@import "../../assets/styles/common.scss";

.shiftModal__modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s;
  z-index: 100;
}

.shiftModal__modal-active {
  opacity: 1;
  pointer-events: all;
  z-index: 100;
}

.shiftModal__modal-content {
  max-width: 440px;
  width: 100%;
  height: 97%;
  margin-right: 27px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 24px;
  background-color: #ffffff;
  box-shadow:
    0px 2px 8px rgba(0, 0, 0, 0.03),
    0px 4px 20px rgba(0, 0, 0, 0.08);
}

.shiftModal__modal-content__info {
  max-width: 440px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.shiftModal__modal-content__header {
  max-width: 440px;
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    @include captionSemiBold;
    color: $colorNeutral100;
  }
}

.shiftModal__modal-content__shift-name {
  max-width: 440px;
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
}

.shiftModal__modal-content__shift-name__text-container {
  margin-bottom: 8px;
  display: flex;
}

.shiftModal__modal-content__timepick-header {
  max-width: 440px;
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-start;
}

.shiftModal__modal-content__timepick-inputs {
  max-width: 440px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.shiftModal__modal-content__timepick-inputs__start,
.shiftModal__modal-content__timepick-inputs__end {
  max-width: 188px;
  width: 100%;
  height: 44px;
  padding: 10px 16px;
  border-radius: 16px;
  border: 1px solid $colorNeutral40;
  background-color: $colorNeutral0;
  cursor: pointer;
}

.shiftModal__buttons-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  button {
    @include body2Regular;
  }

  .deleteButton {
    color: $colorError;
    border-color: $colorError;
  }
}

.shiftModal__modal-content__timepick-header {
  @include body2Regular;
  color: $colorNeutral50;
}
