.table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #e3e5e8;

  & .th,
  & .td {
    &:first-child {
      position: sticky;
      left: 0;
      z-index: 1;

      border: 1px solid #e3e5e8;
    }
  }

  .th {
    border: 1px solid #e3e5e8;
    background-color: #f9f9fa;
    height: 30px;
    vertical-align: middle;
    padding: 0 8px;

    font-family: "Inter";
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #6b6d7e;
  }

  .td {
    border: 1px solid #e3e5e8;
    padding: 0 8px;
    vertical-align: middle;
  }

  .thead {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .tbody {
    .td {
      height: 44px;
      background-color: white;

      color: #212129;

      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }

  .tfoot {
    .td {
      height: 40px;
      background-color: #f9f9fa;

      font-family: "Inter";
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

      &:first-of-type {
        padding-left: 52px;
      }
    }
  }

  .sticky {
    position: sticky;
    left: 0;
    z-index: 1;
  }

  .tr.employee {
    .td {
      &:first-of-type {
        text-align: center;
      }
      &:not(:first-of-type) {
        font-family: "Inter";
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
    }
  }
}

.name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;

  height: 1.2em;
}
