@import "../../assets/styles/common.scss";

.alert-popup {
  background: $colorNeutral0;
  border: 1px solid $colorNeutral30;
  box-shadow:
    0 2px 8px rgba(0, 0, 0, 0.03),
    0 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  position: absolute;
  bottom: 24px;
  right: 24px;
  width: 420px;
  z-index: 500;

  .alert-main {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;

    svg {
      min-width: 28px;
    }
  }

  .exitButton {
    border: none;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px;
    gap: 8px;
    background: $colorNeutral10;
    border-radius: 8px;
    transition: 200ms ease-in-out;
    &:hover {
      background: $colorNeutral20;
    }
    &:active {
      background: $colorNeutral30;
    }
  }
}

.alert-text {
  color: $colorNeutral90;

  .title {
    @include subtitleSemiBold;
  }

  .subtitle {
    @include body2Regular;
  }
}
