.accessRightsPage {
  padding: 1rem 0;

  display: flex;
  flex-direction: column;
  gap: 23px;

  & > h2 {
    font-family: "Inter";
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;

    padding-left: 40px;
  }
}
