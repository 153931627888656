
.button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    border-radius: 4px;
    color: #000;
    outline: none;
    border: none;
    cursor: pointer;

    &.primary {
        background-color: #CAF711;
    }

    &.outlined {
        background-color: transparent;
        border: 1px solid #D9D9D9;
    }

    &.empty {
        background-color: transparent;
        border: none;
        outline: none;
        padding: 0;
    }

    &:disabled {
        cursor: not-allowed;
        background-color: rgba(229, 229, 229, 1);
        color: rgba(0, 0, 0, 0.2);
    }
}