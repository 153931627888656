.label {
  &-container {
    display: flex;
    gap: 8px;
  }
}

.color-square {
  width: 16px;
  height: 16px;
  border-radius: 3px;
}

.with-border {
  border: 1px solid #999999;
}
