@import "assets/styles/common.scss";

.deduction {
  &__header {
    margin-bottom: 29px;
  }
  &__header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 20px 0 0;

    & > h2 {
      @include title3SemiBold;
      font-family: "Inter";
    }
  }
  &__buttons {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}

.table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #e3e5e8;

  & .th,
  & .td {
    &:first-child {
      position: sticky;
      left: 0;
      z-index: 1;

      border: 1px solid #e3e5e8;
    }
  }

  .th {
    border: 1px solid #e3e5e8;
    background-color: #f9f9fa;
    height: 30px;
    vertical-align: middle;
    padding: 0 8px;

    font-family: "Inter";
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #6b6d7e;
  }

  .td {
    border: 1px solid #e3e5e8;
    padding: 0 8px;
    vertical-align: middle;
    position: relative;
  }

  .thead {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .tbody {
    .td {
      height: 44px;
      background-color: white;

      color: #212129;

      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }

  .tfoot {
    .td {
      height: 40px;
      background-color: #f9f9fa;

      font-family: "Inter";
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

      &:first-of-type {
        padding-left: 52px;
      }
    }
  }

  .sticky {
    position: sticky;
    left: 0;
    z-index: 1;
  }

  .tr.employee {
    .td {
      &:first-of-type {
        text-align: center;
      }
      &:not(:first-of-type) {
        font-family: "Inter";
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
    }
  }
}

.num {
  text-align: center !important;
}

.employee-selector {
  padding: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;

  gap: 0;

  :global {
    .ant-select-selector {
      border-radius: 0 !important;
      border: none !important;
      height: 50px !important;

      .ant-select-selection-item {
        line-height: 20px !important;
        margin-top: 4px;
      }
    }
  }
}
