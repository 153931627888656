.page {
    height: 100%;
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.buttonContainer {
    display: flex;
    gap: 16px;
}

.pageHeader {
    display: flex;
    justify-content: space-between;
}

.pageHeaderText {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #212129;
}

.addPositionButton {
    width: fit-content;
    margin-left: auto;
}

.nomenclatureSelect {
    width: 100%;
}

.unitText {
    padding-left: 8px;
}