@import "assets/styles/common.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 32px;

  align-items: center;

  .title {
    font-family: "Inter";
    font-size: 24px;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: left;
    color: $colorTextPrimary;
  }

  .message {
    align-self: flex-start;

    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: left;
  }
}
