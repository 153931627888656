.header {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 6px;
}

.headerItem {
    flex-basis: calc(50% - 3px);
    display: flex;
    flex-direction: column;
    gap: 8px;

    &:last-child {
        flex-grow: 0;
    }
}

.inputItem {
    width: 100%;
    height: 40px;
}

.inputLabel {
    color: rgba(0, 0, 0, 1);
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}

.select {
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 4px;
}