@import "../../../assets/styles/common.scss";

.month-picker {
  position: relative;

  width: fit-content;
  transition: 200ms ease-in-out;
  cursor: pointer;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard syntax */

  &__month {
    border-radius: 12px;
    background: $colorNeutral5;
    border: 1px solid $colorNeutral20;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: fit-content;
    height: 38px;
    padding: 8px 12px;
    gap: 4px;
    p {
      @include tagRegular;
    }
  }

  &__calendar {
    position: absolute;
    z-index: 40;

    top: 45px;
    left: 0;

    &--hidden {
      display: none;
    }
  }
}
