@import "assets/styles/common";

.info-message {
  height: 54px;
  width: 393px;

  display: flex;
  align-items: center;
  gap: 14px;

  padding: 8px 19px;

  background-color: rgba(109, 55, 246, 0.12);
  border: 1px solid $colorStrokeHighlightSecondary;
  border-radius: 8px;

  p {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 500;
    line-height: 19px;
    text-align: left;

    color: $colorTextHighlight;

    flex-shrink: 1;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  & > * {
    flex-shrink: 0;
  }
}
