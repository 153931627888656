@import "../../assets/styles/common.scss";

// Variants

// Basic

.basicInput .basicInput

// search input

.searchInput {
  width: 100%;
  outline: none;
  border: 1px solid $colorNeutral40;
  background-color: $colorNeutral0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $colorNeutral90;
  padding: 6px 8px 6px 36px;
  background-image: url("../../assets/icons/svg/Search.svg");
  background-position: 8px 50%;
  background-repeat: no-repeat;

  &::placeholder {
    color: $colorInactive;
    opacity: 1;
  }

  &::-webkit-input-placeholder {
    color: $colorInactive;
    opacity: 1;
  }

  &::-moz-placeholder {
    color: $colorInactive;
    opacity: 1;
  }
}

.searchInput:focus {
  border-color: $colorNeutral100;
}

// Basic Error

.basicInputError {
  outline: none;
  border: 1px solid $colorErrorNew;
  background-color: $colorPrimary;
}

.basicInputError :focus {
  border-color: $colorErrorNew;
}

.inputField {
  position: relative;

  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 8px;

  padding-bottom: 19px;

  .label {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: -2%;

    color: $colorTextPrimary;
  }

  .errorText {
    position: absolute;
    top: calc(100% - 13px);
    left: 0;

    font-size: 13px;
    line-height: 13px;
    font-weight: 500;
    letter-spacing: -2%;
    color: $colorErrorNew;
  }

  :global {
    .ant-picker {
      padding: 11px 12px !important;
      outline: none !important;
      border: 1px solid $colorStrokePrimary !important;
      border-radius: 4px !important;
      background-color: $colorPrimary !important;

      &-focused {
        border-color: $colorTextSecondary !important;

        .ant-picker-suffix {
          transform: rotateX(-180deg) !important;
        }
      }

      box-shadow: none !important;

      &-disabled {
        border-color: $colorDisabled !important;
        color: $colorTextSecondary !important;
        background-color: $colorDisabled !important;
      }

      &-input {
        input {
          font-family: "Inter" !important;
          font-style: normal !important;
          font-weight: 500 !important;
          font-size: 14px !important;
          line-height: 16px !important;
          color: $colorTextPrimary !important;

          &::placeholder {
            color: $colorTextSecondary !important;
            opacity: 1 !important;
          }

          &::-webkit-input-placeholder {
            color: $colorTextSecondary !important;
            opacity: 1 !important;
          }

          &::-moz-placeholder {
            color: $colorTextSecondary !important;
            opacity: 1 !important;
          }
        }
      }

      &-time-panel-cell {
        &-selected {
          background-color: $colorHighlightPrimary !important;

          .ant-picker-time-panel-cell-inner {
            background-color: $colorHighlightPrimary !important;
          }
        }
      }
    }
  }
}

.basicTimePickerPopup {
  :global {
    .ant-picker {
      &-time-panel-cell {
        &-selected {
          background-color: $colorHighlightPrimary !important;

          .ant-picker-time-panel-cell-inner {
            background-color: $colorHighlightPrimary !important;
          }
        }
      }
    }
  }
}

.resetButton {
  position: absolute;
  right: 10px;
  top: 4px;
  cursor: pointer;
}
