.pageWrapper {
    font-family: "Inter", sans-serif;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 40px 24px
}

.pageHeader {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.headerActions {
    display: flex;
    justify-content: space-between;
}

.pageHeaderText {
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
}

.closePeriodContainer {
    display: flex;
    justify-content: space-between;
    gap: 16px;
}

.closePeriodWrapper {
    display: flex;
    flex-direction: column;
    background-color: rgba(247, 247, 247, 1);
    padding: 16px 24px;
    gap: 6px;
}

.currentMonth {
    display: flex;
    flex-direction: column;
}

.subtitle {
    max-width: 665px;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
}

.icon {
    width: 16px;
}

.monthWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}

.closePeriodContainer {
}

.currentMonth {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.link {
    text-decoration: underline;
    color: rgba(109, 55, 246, 1);
}

.filledText {
    color: rgba(109, 55, 246, 1);
}

.currentMonthLabel {
    color: rgba(153, 153, 153, 1);
    font-size: 13px;
    line-height: 16px;
}

.errorText {
    color: #ef5350;
}