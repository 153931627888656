.activatorWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.activatorIcon {
  min-width: 16px;
  color: #7f8893;
  cursor: pointer;
}

.textContent {
  font-size: 12px;
}

.tooltipContent {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 8px 10px;
  font-weight: 500;
  color: #fff;
  background-color: #041120;
  gap: 24px;
  box-shadow: 0px 8px 10px 0px #2d414733, 0px 6px 30px 0px #2d41471f,
    0px 16px 24px 0px #2d414724;

  z-index: 9999;
}
