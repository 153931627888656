@import "assets/styles/common.scss";

.fund {
  &__wrapper {
    max-height: calc(100vh - 56px);
    height: 100%;

    padding: 16px 20px;

    font-family: "Inter";

    * {
      letter-spacing: -0.02em;
    }

    display: flex;
    flex-direction: column;
    gap: 16px;

    .tables-container {
      border-radius: 10px;
      overflow: auto;
      width: 100%;
      height: fit-content;
    }
  }

  &-title {
    font-size: 24px;
    font-weight: 800;
    line-height: 32px;
    text-align: left;
  }

  &__header {
    width: 100%;
    max-width: calc(100vw - 280px - 60px);
    margin-top: 20px;

    display: flex;
    gap: 58px;
    justify-content: space-between;
    align-items: stretch;

    &-container {
      width: 100%;

      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-auto-flow: column;

      align-items: center;

      gap: 24px;
    }

    .info-side {
      display: flex;
      flex-direction: column;
      gap: 22px;
      justify-content: space-between;
    }

    .info-message {
      height: 54px;
      width: 393px;

      display: flex;
      align-items: center;
      gap: 14px;

      padding: 8px 19px;

      background-color: rgba(109, 55, 246, 0.12);
      border: 1px solid $colorStrokeHighlightSecondary;
      border-radius: 8px;

      p {
        font-family: "Inter";
        font-size: 12px;
        font-weight: 500;
        line-height: 19px;
        text-align: left;

        color: $colorTextHighlight;

        flex-shrink: 1;

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      & > * {
        flex-shrink: 0;
      }
    }

    .buttons-group {
      justify-self: end;
      display: flex;
      align-items: center;
      gap: 8px;

      margin-bottom: 4px;

      button {
        width: 125px;
        padding: 8px 11px;

        &:disabled {
          cursor: not-allowed;
          &:active {
            box-shadow: none;
          }

          background-color: #f1f2f4;
          border: 1px solid #f1f2f4;
          color: #a4a5b0;
        }
      }
    }
  }
}

.tabs {
  width: fit-content;
  background-color: #f9f9fa;

  border: 1px solid #e3e5e8;
  border-radius: 14px;
  padding: 4px;

  :global {
    .ant-segmented-item {
      border-radius: 10px;
      box-shadow: none;
      border: none;

      &-selected {
        background-color: $colorHighlightPrimary;
        .ant-segmented-item-label {
          color: black;
        }
      }

      &-label {
        font-family: "Inter";
        font-size: 14px;
        font-weight: 500;

        color: #272729;
      }
    }
    .ant-segmented-thumb-motion-appear-active {
      background-color: $colorHighlightPrimary;
      border-radius: 10px;
    }
  }
}

.table {
  width: fit-content;
  border-collapse: separate;
  table-layout: fixed;

  border-collapse: separate;
  border: 1px solid #e3e5e8;

  &-rounding {
    &__top {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      .rounding:first-child {
        .tr:first-of-type {
          .th,
          .td {
            &:first-of-type {
              border-top-left-radius: 10px;
            }
            &:last-of-type {
              border-top-right-radius: 10px;
            }
          }
        }
      }
    }
    &__bottom {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      .rounding:last-child {
        .tr:last-of-type {
          .th,
          .td {
            &:first-of-type {
              border-bottom-left-radius: 10px;
            }
            &:last-of-type {
              border-bottom-right-radius: 10px;
            }
          }
        }
      }
    }
  }

  th,
  td:first-child {
    vertical-align: middle;
    text-align: center;
  }

  td,
  th {
    border-left: solid #e3e5e8 1px;
    border-top: solid #e3e5e8 1px;
  }

  th {
    border-top: none;
  }

  td:first-child,
  th:first-child {
    border-left: none;
  }
}

.thead {
  .th {
    height: 30px;
  }
  .tr {
    &:first-child {
      background-color: #e8e8e8;

      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
    }
    &:last-child {
      background-color: #f9f9fa;

      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
    }
  }
}

.th,
.td {
  // border: 1px solid #e3e5e8;
}

.th {
  color: #6b6d7e;
}

.td {
  color: #212129;
}

.clickable {
  cursor: pointer;
}

.employee {
  border-width: 1px 0;

  span {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.tbody {
  border: 1px solid #e3e5e8;

  .td {
    height: 44px;

    vertical-align: middle;
    text-align: left;
    padding-left: 8px;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    position: relative;

    &-input {
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #212129;

      padding-left: 8px;

      outline: none;
      border: none;
      position: absolute;
      left: 0px;
      right: 0px;
      top: 0px;
      bottom: 0px;

      &:focus {
        padding-left: 7px;

        border: 1px solid $colorTextSecondary;
      }
    }

    &-bold {
      font-weight: 700;
    }
  }

  .num {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    color: #6b6d7e;
    padding: 0;
  }
}

.tfoot {
  .tr {
    background-color: #f9f9fa;
  }
  .td {
    height: 40px;

    vertical-align: middle;
    text-align: left;

    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding-left: 8px;

    &:first-child {
      text-align: left;
      padding-left: 50px;
    }
  }
}

.total-tfoot {
  .tr {
    background-color: #e5e5e5;
  }
}

.tabs__container {
  display: flex;
  align-items: center;
  gap: 8px;

  .tab {
    outline: none;
    cursor: pointer;

    padding: 8px 12px;

    font-size: 14px;
    font-weight: 500;
    color: $colorTextPrimary;

    border-radius: 100px;
    border: 1px solid $colorStrokePrimary;
    background-color: transparent;

    &__active {
      @extend .tab;
      color: $colorTextHighlight;
      border-color: $colorStrokeHighlightSecondary;
    }
  }
}
