@import "assets/styles/common.scss";

.table {
  font-family: "Inter";
  table-layout: fixed;

  border-collapse: collapse;

  border: 1px solid #d9d9d9;

  .td,
  .th {
    border: 1px solid #d9d9d9;
  }
}

.thead {
  & > .tr > .th {
    font-size: 15px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: left;

    background-color: #f7f7f7;
    color: #272729;

    &:nth-last-of-type(2) {
      border-right: none;
    }
    &:nth-last-of-type(1) {
      border-left: none;
    }

    padding: 19px 8px;

    &:nth-child(1) {
      padding-left: 16px;
    }
  }
}

.tbody {
  .td {
    position: relative;
    padding: 12px 8px;
    &:nth-child(1) {
      padding-left: 16px;
    }

    &:not(:nth-last-of-type(1)) {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.02em;
      text-align: left;

      color: #272729;
    }
  }
}

.buttons-footer {
  .td {
    background-color: #f9f9fa;

    padding: 12px 16px;
  }
}

.sum-footer {
  .td {
    border: none;
  }

  .error-message {
    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: -0.02em;
    text-align: left;
    color: $colorErrorNew;

    margin-left: 20px;
  }

  .sum {
    display: flex;
    justify-content: space-between;

    padding: 12px 8px;
    padding-left: 29px;

    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #999999;
    }
    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.02em;
      text-align: right;
      color: #000000;
    }
  }
}

.bucket-button {
  width: 24px;
  height: 24px;

  border: none;
  background-color: transparent;

  margin: auto;
  display: grid;
  place-content: center;
}

.reset-button {
  border: none;
  background-color: transparent;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #6d37f6;
}

.input-sum {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  border: none;

  padding-left: 8px;

  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  &:focus-visible {
    outline: none;
    border: none;
  }
}

.employee-selector {
  padding: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;

  gap: 0;

  :global {
    .ant-select-selector {
      border-radius: 0 !important;
      border: none !important;
      height: 50px !important;

      .ant-select-selection-item {
        line-height: 20px !important;
        margin-top: 4px;
      }
    }
  }
}

.status {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: #272729;
}

.disabled {
  background-color: #f7f7f7;
  .status {
    color: #999999;
  }
}
