@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

.skeleton {
    display: inline-block;
    overflow: hidden;
    background-color: #F0F4F7;

    &.animation {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            background-image: linear-gradient(
                            90deg,
                            rgba(#fff, 0) 0,
                            rgba(#fff, 0.2) 20%,
                            rgba(#fff, 0.5) 60%,
                            rgba(#fff, 0)
            );
            transform: translateX(-100%);
            animation: shimmer 1s infinite;
            inset: 0;
        }
    }
}
