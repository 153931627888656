@import "../styles";

.timesheet-header {
  @include title3SemiBold;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 20px;
  gap: 16px;
}

.timesheet-filters {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 20px 8px;
  gap: 8px;
}

.timesheet-tables__wrapper {
  position: relative;
}

.timesheet__time-table-wrapper {
  max-width: 100%;
  overflow-y: hidden;
}

.timesheet-tables__inner {
  display: flex;
  flex-direction: row;
  margin: 0 20px 20px;
  max-width: 100%;
  border: 1px solid $colorNeutral20;
  border-radius: 10px;

  &.safari {
    .timesheet__table-body {
      .timesheet__table-body-row {
        &:last-child {
          .timesheet__table-body-element {
            border-bottom: none;
            height: 100%;
            height: -webkit-fill-available;
          }
        }
      }
    }
  }

  .timesheet__table {
    display: flex;
    flex-direction: column;
    white-space: nowrap;

    &.timesheet__names-table {
      max-width: 378px;
      min-width: 378px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      overflow-y: visible;

      .timesheet__table-header {
        border-right: 1px solid $colorNeutral20;

        border-top-left-radius: 10px;
        .timesheet__table-header-element {
          &:first-child {
            border-top-left-radius: 10px;
            min-width: 270px;
          }
          &:last-child {
            width: 107px;
          }
        }
      }
      .timesheet__table-body {
        .timesheet__table-body-row {
          border-right: 1px solid $colorNeutral20;

          .timesheet__table-body-element {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            &:first-child {
              min-width: 270px;
            }
            &:last-child {
              width: 107px;
            }
          }
        }
      }
    }

    &.timesheet__time-table {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      overflow-x: auto;
      overflow-y: hidden;

      max-height: 100%;
      max-width: 100%;

      .timesheet__table-header {
        border-top-right-radius: 10px;
      }

      .timesheet__table-header-element,
      .timesheet__table-body-element {
        min-width: 64px;
        min-height: 40px;
        align-items: center;
        justify-content: center;
      }

      .timesheet__table-body-row:last-child {
        [data-title]::after {
          bottom: 38px;
          left: 53px;
        }
      }
    }

    .timesheet__table-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: $colorSecondary;

      .timesheet__table-header-element {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px 8px 12px 8px;
        gap: 4px;
        background: $colorNeutral5;
        border-bottom: 2px solid $colorNeutral20;
        white-space: nowrap;
        position: relative;

        &:last-child {
          svg {
            display: none;
          }
        }

        svg {
          position: absolute;
          right: 0;
        }
      }
    }

    .timesheet__table-body {
      display: flex;
      flex-direction: column;
      @include body2Regular;
      color: $colorNeutral90;

      .timesheet__table-body-row {
        display: flex;
        justify-content: space-between;

        .timesheet__table-body-element {
          display: flex;
          align-items: center;
          padding: 12px 8px;
          gap: 4px;
          border-bottom: 1px solid $colorNeutral20;
          min-height: 45px;
          position: relative;

          &.weekday {
            background-color: $colorInfoBg;
          }

          &:not(:last-child) {
            border-right: 1px solid $colorNeutral20;
          }

          &.bold {
            font-weight: 500;
          }
        }
      }
    }
  }
}

.timesheet-wrapper {
  .timesheet__popup {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 20px;
    gap: 16px;
    .header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px;
      gap: 16px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      color: $colorNeutral90;
      button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 12px;
        gap: 8px;
        background: $colorNeutral10;
        border-radius: 10px;
        @include body2Medium;
        color: $colorPrimaryDark;
        border: navajowhite;

        &:hover {
          background: $colorNeutral20;
        }

        &:active {
          background: $colorNeutral30;
        }
      }
    }
    .body {
      width: 100%;

      .shift-row {
        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }

      .delete {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 4px;
        height: 26px;
        gap: 8px;
        background: $colorNeutral10;
        border-radius: 10px;
        border: none;

        &:hover {
          background: $colorNeutral20;
        }
        &:active {
          background: $colorNeutral30;
        }
      }
      .inputs {
        display: flex;
        gap: 8px;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
