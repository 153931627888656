@import "../../assets/styles/common.scss";

.header {
  &__container {
    width: 100%;
    min-height: 56px;
    padding: 0 8px;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $colorNeutral30;
    background-color: $colorNeutral0;
  }
  &__logo-container {
    display: flex;
    align-self: center;
    width: 166px;
    height: 24px;
    margin-left: 37px;
  }
  &__menu-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 20px;
  }
  &__date {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 0px;
    gap: 4px;
    @include body2Regular;
    color: $colorNeutral90;
  }
  &__notifications {
    &__bell {
      background-color: #fff;
      border-radius: 8px;
      padding: 2px;
      cursor: pointer;
      &--opened {
        background-color: $colorNeutral20;
        border-radius: 8px;
        padding: 2px;
        cursor: pointer;
      }
    }
  }
  &__profile {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &__pic {
      width: 38px;
      height: 38px;
      margin-right: 12px;
      display: flex;
      border-radius: 24px;
      border: 1px solid $colorNeutral30;
      img {
        width: 38px;
        max-width: 38px;
      }
    }
    &__info {
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      &-name,
      &-organization {
        white-space: nowrap;
      }
      &-name {
        @include body2Medium;
        color: $colorNeutral90;
      }
      &-organization {
        @include captionSemiBold;
        color: $colorAccent;
      }
    }
    &__submenu {
      position: absolute;
      right: 0px;
      top: 100%;
      padding: 12px;
      display: flex;
      flex-direction: column;
      text-align: right;
      border-radius: 0px 0px 16px 16px;
      border: 1px solid $colorNeutral30;
      background-color: $colorNeutral10;
      z-index: 2;
      &-vision {
        display: none;
      }
      &__item {
        margin-bottom: 8px;
        cursor: pointer;
        @include body1Regular;
        color: $colorNeutral100;
        &:last-of-type {
          margin-bottom: 0px;
        }
        &:hover {
          color: $colorNeutral50;
        }
      }
    }
  }
}

.modalMainContent {
  padding: 0 20px;
  margin-top: 1rem;
  width: 100%;
}

.about-modal {
  &__title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $colorNeutral50;
  }

  &__info {
    font-family: "Inter";
    font-style: normal;
    font-weight: 550;
    font-size: 14px;
    line-height: 22px;
  }
}

.spotSelect {
  width: 100%;
}

// TODO: Костыль. Переписать нормально.
.link {
  text-decoration: none;
  color: black;
  &:hover {
    color: $colorNeutral50;
  }
}
