@import "../styles.scss";

.revenueOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(44, 44, 44, 0.4);
  z-index: 50;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  transition: 200ms ease-in-out;
}

.revenueOverlay_active {
  opacity: 1;
  pointer-events: all;
}
