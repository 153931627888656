@import "assets/styles/common.scss";

.shift {
  position: relative;
  cursor: pointer;

  .duration {
    font-size: 14px;
    font-weight: 800;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: center;
  }

  .circle {
    position: absolute;
    right: 5px;
    top: 5px;

    width: 9px;
    height: 9px;
    border-radius: 100%;
    background-color: black;
  }

  .warning {
    position: absolute;
    right: 4px;
    bottom: 4px;

    display: grid;
    place-content: center;
  }

  &__clear {
    &:hover {
      border-color: $colorStrokeHighlightSecondary;

      &::before {
        content: url("../../../../../../assets/icons/svg/schedule/shift/purple_plus_icon.svg");

        position: absolute;
        left: 50%;
        top: 50%;

        transform: translate(-50%, -50%);
      }
    }

    &-unwanted {
      background-color: #fbe1e1;
      border-color: $colorErrorNew !important;

      &_all {
        background-image: linear-gradient(
          135deg,
          $colorErrorNew 4.55%,
          transparent 4.55%,
          transparent 50%,
          $colorErrorNew 50%,
          $colorErrorNew 54.55%,
          transparent 54.55%,
          transparent 100%
        );
        background-size: 15.56px 15.56px;

        border-color: $colorErrorNew !important;
      }
    }
  }
}

.tooltip {
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 16px;
  border-radius: 8px;

  background-color: $colorPrimary;
  box-shadow: 0px 4px 25px 0px #0000001a;

  z-index: 99999;
  &-arrow {
    box-shadow: 0px 4px 25px 0px #0000001a;
  }
}

.unwanted {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 4px;

  max-width: 224px;
  overflow: hidden;

  &-row {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  & > p {
    max-width: 200px;
    margin-left: 24px;
  }
}

.comment {
  padding-left: 24px;
  position: relative;

  max-width: 224px;

  &::before {
    content: " ";
    width: 16px;
    height: 16px;

    background: url("../../../../../../assets/icons/svg/schedule/purple-comment_icon.svg")
      no-repeat center/contain;

    position: absolute;
    left: 0;
    top: 0;
  }

  .message {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: left;

    color: $colorTextPrimary;

    overflow: hidden;
    text-overflow: ellipsis;
  }
}
