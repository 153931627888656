@import "../../assets/styles/common.scss";

.resetPass {
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
  }

  &__container {
    max-width: 400px;
    width: 100%;
    margin: 15px auto 0px;
    display: flex;
    flex-direction: column;
    border-radius: 24px;
    background-color: $colorNeutral0;
    box-shadow:
      0px 2px 8px rgba(0, 0, 0, 0.03),
      0px 4px 20px rgba(0, 0, 0, 0.08);
  }

  &__logo-container {
    margin: 0px auto 22px;
    padding-top: 25px;
  }

  &__line {
    max-width: 400px;
    width: 100%;
    height: 2px;
    background-color: $colorNeutral30;
  }

  &__authContainer {
    padding: 32px;
    display: flex;
    flex-direction: column;

    &-header {
      margin-bottom: 8px;
      @include captionSemiBold;
      color: $colorNeutral100;
    }

    &-wellcome_message {
      margin-bottom: 32px;
      @include body2Regular;
      color: $colorNeutral50;
    }

    &-login {
      margin-bottom: 32px;
    }

    &-login__text-container {
      display: flex;
      margin-bottom: 8px;
      @include body2Medium;
      color: $colorNeutral50;
    }
  }

  &__buttons-container {
    display: flex;
    justify-content: space-between;
  }
}
