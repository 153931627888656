@import "../../assets/styles/common.scss";

.modal__overlay {
  display: grid;
  place-content: center;

  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(44, 44, 44, 0.4);
  z-index: 299;
  cursor: pointer;

  font-family: "Inter";
}

.modal__container {
  position: relative;
  z-index: 300;

  cursor: default;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 40px;

  background-color: $colorPrimary;

  .modal__title {
    width: 100%;

    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: 16px;
  }

  .button-back {
    display: grid;
    place-content: center;

    outline: none;
    cursor: pointer;

    border: none;
    background-color: transparent;

    width: 32px;
    height: 32px;
  }

  .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: $colorTextPrimary;
    text-align: center;
  }

  .modal__exitButton {
    position: absolute;
    top: 12px;
    right: 12px;

    width: 32px;
    height: 32px;

    outline: none;

    border: none;

    display: grid;
    place-content: center;

    transition: 0.2s ease-in-out;

    background-color: transparent;

    &:hover {
      background-color: $colorNeutral20;
    }
    &:active {
      background-color: $colorNeutral30;
    }
  }

  .modal__main {
    margin-top: 24px;
    width: 100%;
    height: 100%;
  }

  .modal__notification {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin: 4px 0;

    .error-text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
  }
}

.modal__buttons-group {
  display: flex;
  align-items: stretch;

  gap: 16px;

  & > button {
    min-width: 110px;
  }
}
