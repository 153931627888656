@import "../styles.scss";

.statementedit__header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 20px;
  gap: 16px;

  .breadcrumbs {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    gap: 8px;
    cursor: pointer;

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $colorNeutral50;

    .grey {
      &:hover {
        color: $colorNeutral70;
      }

      &:active {
        color: $colorNeutral80;
      }
    }

    .black {
      color: $colorNeutral90;
    }
  }

  .title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;
    gap: 16px;

    .text {
      display: flex;
      gap: 16px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: $colorNeutral90;

      @media (max-width: 1060px) {
        font-size: 20px;
      }

      .backlink {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 4px;
        gap: 8px;
        width: 32px;
        height: 32px;
        background: $colorNeutral0;
        border: 1px solid $colorNeutral30;
        border-radius: 10px;
        cursor: pointer;

        &:hover {
          background: $colorNeutral10;
          border: 1px solid $colorNeutral40;
        }

        &:active {
          background: $colorNeutral20;
          border: 1px solid $colorNeutral50;
        }
      }
    }

    .buttons {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      flex-grow: 1;
      justify-content: flex-end;

      .deleted {
        display: flex;
        padding: 5px 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 10px;
        border: 1px solid $colorErrorFill;
        background: $colorErrorBg;
        cursor: default;

        p {
          color: $colorErrorFill;
          @include body2Regular;
        }
      }
    }

    .status {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 8px;
      gap: 8px;
      border-radius: 10px;

      &.in {
        color: $colorSuccessFill;
        border: 1px solid $colorSuccessFill;
      }

      &.out {
        color: $colorError;
        border: 1px solid $colorError;
      }
    }
  }
}

.operation-edit {
  display: flex;
  width: 100%;
  padding: 16px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;

  &__inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    &-row {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;

      &__element {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        flex: 1 0 0;
        position: relative;

        .daypicker {
          height: 44px;
          border-radius: 16px;
        }

        .calendar-modal__wrapper_active.day {
          width: 400px;
          margin-top: 10px;

          .react-calendar__month-view__weekdays {
            padding-left: 18px;
            padding-bottom: 10px;
          }
        }

        input {
          color: $colorNeutral100;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }

        &.button {
          align-items: flex-end;
          height: 100%;

          button {
            height: 40px;
            margin-top: 30px;
            display: flex;
            width: 217px;
            padding: 10px 12px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 10px;
            background: $colorNeutral90;
            border: none;
            @include body2Medium;
            color: $colorPrimaryLight;

            &:hover {
              background: $colorNeutral80;
            }

            &:active {
              background: $colorNeutral100;
            }
          }
        }

        span {
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          color: $colorNeutral50;

          &.max {
            position: absolute;
            right: 0;
          }
        }
      }
    }
  }

  &__table {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid $colorNeutral20;

    &-header {
      display: flex;
      align-items: flex-start;
      align-self: stretch;
      background: $colorNeutral5;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;

      &__element {
        display: flex;
        padding: 12px 8px;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        color: $colorNeutral60;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 133.333% */
        position: relative;
        border-bottom: 2px solid $colorNeutral20;
        min-width: 34px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:first-child {
          border-top-left-radius: 10px;
          justify-content: center;
        }

        &:nth-child(2) {
          width: 320px;
          flex: 2 2;
        }

        &:nth-child(3) {
          width: 162px;
          flex: 1 1;
        }

        &:nth-child(4) {
          width: 320px;
          flex: 2 2;
        }

        &:nth-child(5) {
          width: 162px;
          flex: 1 1;
        }

        &:last-child {
          border-top-right-radius: 10px;
          width: 162px;
          flex: 1 1;
        }

        svg {
          position: absolute;
          right: 0;
        }
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;

      &__position {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        @include body2Medium;
        color: $colorNeutral90;

        &:last-child {
          .operation-edit__table-body__position__row:last-child {
            .operation-edit__table-body__position__row-element {
              border-bottom: none;
            }
          }
        }

        &__row {
          display: flex;
          width: 100%;

          &:first-child {
            .operation-edit__table-body__position__row-element {
              padding: 0 8px;

              &:nth-child(2) {
                width: 320px;
                flex: 2 2;
              }

              &:nth-child(3) {
                width: 162px;
                flex: 1 1;
                padding: 0 9px 0 8px;
              }

              &:nth-child(4) {
                width: 320px;
                flex: 2 2;

                .ant-select-selector {
                  padding: 0 16px;
                }
              }

              &:nth-child(5) {
                width: 162px;
                flex: 1 1;
              }

              &:last-child {
                width: 162px;
                flex: 1 1;
              }
            }
          }

          &:not(:first-child) {
            .operation-edit__table-body__position__row-element {
              &:nth-child(2) {
                flex: 3 3;
                padding: 0 16px;
              }

              &:nth-child(3) {
                flex: 4 4;
                padding: 0 24px;
              }
            }
          }

          &-element {
            display: flex;
            height: 44px;
            min-width: 34px;
            justify-content: flex-start;
            align-items: center;
            gap: 4px;
            position: relative;
            border-bottom: 1px solid $colorNeutral20;
            flex: 1 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &__input {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              color: $colorNeutral90;
              border: none;
              outline: none;
              padding-left: 8px;
            }

            span {
              color: $colorInactive;
            }

            &:not(:last-child) {
              border-right: 1px solid $colorNeutral20;
            }

            &:first-child {
              flex: 0 0;
              color: $colorNeutral60;
              border-right: none;
              justify-content: center;
            }

            &:first-child {
              border-top-left-radius: 10px;
              justify-content: center;
            }

            &:nth-child(2) {
              width: 320px;
              flex: 2 2;
            }

            &:nth-child(3) {
              width: 162px;
              flex: 1 1;
            }

            &:nth-child(4) {
              width: 320px;
              flex: 2 2;
            }

            &:nth-child(5) {
              width: 162px;
              flex: 1 1;
            }

            &:last-child {
              border-top-right-radius: 10px;
              width: 162px;
              flex: 1 1;
            }

            .ant-select {
              width: 100%;
              height: 43px;
              border: none;
              outline: none;
              box-shadow: none !important;

              &:hover {
                border-color: transparent !important;
                box-shadow: none !important;
                outline: none;

                .ant-select-selector {
                  border-color: transparent !important;
                  box-shadow: none !important;
                  outline: none;
                }
              }

              &.open {
                border: none;
                outline: none;

                .ant-select-selector {
                  width: 100%;
                  height: 43px;
                  border: none;
                }
              }

              .ant-select-selector {
                width: 100%;
                height: 43px;
                border: none;

                span {
                  @include body2Medium;
                  color: $colorNeutral90;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                }
              }
            }
          }
        }
      }
    }
  }
}

.add-button-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.table-container {
  width: 100%;
}
