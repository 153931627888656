@import "../../../../assets/styles/common";

.rulesMatrix {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.checkboxContainer {
  display: flex;
  align-items: center;
}

.mainCheckboxLabel {
  font-weight: 700;
  padding-bottom: 0.5rem;
}

.mainCheckboxContainer {
  margin-bottom: 0.25rem;
}

.mainRow {
  margin-bottom: 0.25rem;
}

.subRow {
  display: flex;

  &__item {
    margin-right: 0.5rem;
  }
}
