@import "assets/styles/common.scss";

.form {
  display: flex;
  flex-direction: column;

  .buttons-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.owners-container {
  display: flex;
  flex-direction: column;

  gap: 12px;

  overflow: auto;

  padding-right: 40px;
  margin-right: -40px;
}

.owner {
  font-family: "Inter";

  display: flex;
  flex-direction: column;
  gap: 8px;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h5 {
      font-size: 14px;
      font-weight: 800;
      line-height: 32px;
      text-align: left;
    }
  }

  &-fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: stretch;
    & > * {
      flex-shrink: 0;
      flex-grow: 1;
    }
  }

  padding-bottom: 8px;

  border-bottom: 2px solid #dbdbdb;

  &:nth-last-of-type(1) {
    border-bottom: none;
  }

  &-delete {
    outline: none;
    cursor: pointer;

    border: none;
    background-color: transparent;

    display: flex;
    align-items: center;
    gap: 8px;

    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: $colorErrorNew;

    &:disabled {
      cursor: not-allowed;
      color: #999999;
    }
  }
}

.info {
  &-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 14px;
      font-weight: 600;
    }

    margin-bottom: 12px;
  }

  &-error {
    color: $colorErrorNew;
  }
}
