.table {
  width: fit-content;
  border-collapse: collapse;
  table-layout: fixed;
  border: 1px solid #dbdbdb;
  border-radius: 10px;

  & .th,
  & .td {
    &:first-child {
      position: sticky;
      left: 0;
      z-index: 0;
    }
  }

  & > .thead .tr,
  & > .tfoot .tr {
    & > .th {
      border: 1px solid #dbdbdb;
    }

    &::first-of-type {
      & > .th,
      & > .td {
        &:first-child {
          border-top-left-radius: 10px;
        }
        &:last-child {
          border-top-right-radius: 10px;
        }
      }
    }

    &::last-of-type {
      & > .th,
      & > .td {
        &:first-child {
          border-bottom-left-radius: 10px;
        }
        &:last-child {
          border-bottom-right-radius: 10px;
        }
      }
    }
  }

  & > .thead {
    position: sticky;
    top: 0;
    z-index: 3;

    .th {
      font-family: "Inter";
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

      color: #6b6d7e;
      background-color: #f9f9fa;

      vertical-align: middle;

      height: 33px;
    }
  }

  & > .tfoot {
    position: sticky;
    bottom: 0;
    z-index: 3;

    & .td {
      background-color: #e5e5e5;
      padding: 0 8px;

      height: 40px;
      vertical-align: middle;

      font-family: "Inter";
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

      &:first-child {
        padding-left: 52px;
      }
      &:last-child {
        padding-left: 8px;
      }
    }
  }
}

.bep-info {
  background-color: #9747ff;
  border-top-left-radius: 10px;
  min-width: 133px;
  width: fit-content;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  padding-left: 16px;
  padding-right: 15px;

  span {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    color: #ffffff;
  }
}
