@import "../../assets/styles/common.scss";

/* Common */
.buttonCommon {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*  fillButton */
.fillButton {
  @include body2Medium;
  border: none;
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  color: $colorNeutral0;
  background-color: $colorNeutral90;
  transition: 200ms ease-in-out;
}

.fillButton:hover {
  background-color: $colorNeutral80;
}

.fillButton:active {
  background-color: $colorNeutral100;
}

.fillButton:disabled {
  border: none;
  color: $colorNeutral50;
  background-color: $colorNeutral20;
  cursor: default;
}

/*  fillLightButton */
.fillLightButton {
  @include body2Medium;
  border: none;
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  color: $colorPrimaryDark;
  background-color: $colorNeutral10;
  transition: 200ms ease-in-out;

  &:hover {
    background-color: $colorNeutral20;
  }

  &:active {
    background-color: $colorNeutral30;
  }

  &:disabled {
    border: none;
    color: $colorInactive;
    background-color: $colorNeutral10;
    cursor: default;
  }
}

/* old fillButton */
.oldFillButton {
  border: none;
  color: $colorNeutral0;
  background-color: $colorNeutral90;
  transition: 200ms ease-in-out;
}

.oldFillButton:hover {
  background-color: $colorNeutral80;
}

.oldFillButton:active {
  background-color: $colorNeutral100;
}

.oldFillButton:disabled {
  border: none;
  color: $colorNeutral50;
  background-color: $colorNeutral20;
  cursor: default;
}

/* strokeButton */
.strokeButton {
  border: 1px solid $colorNeutral100;
  color: $colorNeutral90;
  background-color: $colorNeutral0;
}

.strokeButton:hover {
  border: 1px solid $colorNeutral80;
  color: $colorNeutral80;
  background-color: $colorNeutral20;
}

.strokeButton:active {
  border: 1px solid $colorNeutral100;
  color: $colorNeutral100;
  background-color: $colorNeutral40;
}

.strokeButton:disabled {
  border: 1px solid $colorNeutral30;
  color: $colorNeutral50;
  background-color: $colorNeutral20;
  cursor: default;
}
/* strokeDeleteButton */

.strokeDeleteButton {
  border: 1px solid $colorErrorFill;
  color: $colorErrorFill;
  background-color: $colorNeutral0;
}

.strokeDeleteButton:hover {
  border: 1px solid $colorErrorFill;
  color: $colorErrorFill;
  background-color: $colorNeutral20;
}

.strokeDeleteButton:active {
  border: 1px solid $colorErrorFill;
  color: $colorErrorFill;
  background-color: $colorNeutral40;
}

/* ghostButton */

.ghostButton {
  border: none;
  color: $colorNeutral90;
  background-color: $colorNeutral0;
}

.ghostButton:hover {
  border: none;
  color: $colorNeutral80;
  background-color: $colorNeutral20;
}

.ghostButton:active {
  border: none;
  color: $colorNeutral100;
  background-color: $colorNeutral40;
}

.ghostButton:disabled {
  border: none;
  color: $colorNeutral50;
  background-color: $colorNeutral20;
  cursor: default;
}

/* for password */

.shapeButton {
  border: none;
  background-color: transparent;
  background-image: url("../../assets/icons/svg/shape.svg");
  background-repeat: no-repeat;
}

.eyeButton {
  border: none;
  background-color: $colorNeutral0;
  background-image: url("../../assets/icons/svg/eye.svg");
  background-repeat: no-repeat;
}

.createButton {
  display: flex;
  padding: 6px 12px 6px 40px;
  @include body2Medium;
  color: $colorPrimaryDark;
  border: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  background: $colorNeutral10 url("../../assets/icons/svg/cross.svg") no-repeat
    12px 50%;
}

.createButton:hover {
  background-color: $colorNeutral20;
}
.createButton:active {
  background-color: $colorNeutral30;
}

.triplePointsButton {
  border: none;
  border-radius: 8px;
  background-color: $colorNeutral0;
  background-image: url("../../assets/icons/svg/triplepoints.svg");
  background-repeat: no-repeat;
  min-width: 28px;
}

.triplePointsButton:hover {
  background-image: url("../../assets/icons/svg/triplepointsfilled.svg");
}

/* emploee choose */

.emploeeChooseButton {
  border: none;
  background-color: $colorNeutral0;
  background-image: url("../../assets/icons/svg/employeearrowright.svg");
  background-repeat: no-repeat;
}

.emploeeOpenChooseButton {
  border: none;
  background-color: transparent;
  background-image: url("../../assets/icons/svg/employeearrowdown.svg");
  background-repeat: no-repeat;
}

.closeButton {
  border: none;
  background-color: $colorNeutral0;
  background-image: url("../../assets/icons/svg/close.svg");
  background-repeat: no-repeat;
}

.editButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px 6px 40px;
  gap: 8px;
  border-radius: 10px;
  background: $colorNeutral10 url("../../assets/icons/svg/pencil.svg") no-repeat
    12px 50%;
  border: none;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $colorPrimaryDark;

  &:hover {
    background-color: $colorNeutral20;
  }

  &:active {
    background-color: $colorNeutral30;
  }
}
