@import "assets/styles/common.scss";

.unwanted {
  display: flex;
  align-items: start;
  gap: 8px;

  padding: 7px;

  border: 1px solid $colorErrorNew;
  border-radius: 8px;

  & > * {
    flex-shrink: 0;
  }
}

.unwanted-time {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;

  color: $colorErrorNew;
}

.unwanted-reason {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;

  max-width: 371px;
  overflow: hidden;
  text-overflow: ellipsis;

  color: $colorTextPrimary;
}
