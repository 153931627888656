.reportTable {
  background-color: black;
  width: fit-content;
  table-layout: fixed;
  background-color: #fff;
  border-spacing: 4px !important;
  border-collapse: separate;
  margin-top: 20px;

  th,
  td {
    text-align: left;
    padding: 12px;
  }

  thead {
    th {
      background-color: #f4f4f4;
      font-weight: bold;
      font-size: 14px;
      color: #555;
      text-align: center;
    }
  }

  tbody {
    tr {

      &:hover {
        background-color: #f5f5f5;
      }
    }

    td {
      font-family: "Inter";
      font-size: 14px;
      font-weight: 800;
      line-height: 16px;
      letter-spacing: -0.02em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

      &:first-child {
        border: none;
      }

      &:not(:first-child) {
        text-align: center;
        border: 1px solid #d7d7d7;
        border-radius: 4px;
      }
    }
  }
}

.planCell,
.factCell {
  padding: 2px 0 !important;
}

.tableHeader {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
}

.reportTable {
  & .title {
    box-shadow: 0px -18px 0px -1px rgba(255, 255, 255, 1);
    position: sticky;
    left: -20px;
    z-index: 10;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;

    &:first-child {
      z-index: 15;
    }
  }
}

.subHeader {
  height: 13px;
  & > th {
    font-family: Inter;
    font-size: 13px !important;
    font-weight: 500 !important;
    line-height: 13px !important;
    letter-spacing: -0.02em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}

