@import "assets/styles/common.scss";

.wrapper {
  max-height: calc(100vh - 56px);
  height: 100%;

  padding: 16px 20px;

  font-family: "Inter";

  * {
    letter-spacing: -0.02em;
  }

  display: flex;
  flex-direction: column;
  gap: 16px;

  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    gap: 18px;

    width: max-content;
  }
}

.modal {
  &-container {
    display: flex;
    flex-direction: column;
    gap: (24px - 19px);
  }

  &-buttons {
    margin-top: calc(32px - 24px);
    align-self: flex-end;
  }
}

.field {
  &-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &-label {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #000000;
  }
  &-description {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #cccccc;
  }
}
