@import "../../../assets/styles/common.scss";

.revenuePlanningSkeleton__container {
  margin-top: 56px;
  margin-left: 296px;

  .revenuePlanningSkeleton__summary-container {
    padding-top: 148px;
  }

  .revenuePlanningSkeleton__parts-container {
    padding-top: 33px;
  }

  .revenuePlanningSkeleton__calendar-container {
    margin-top: 40px;
  }
}
