@import "../../assets/styles/common.scss";

.registry-table__footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 24px;
  background: $colorNeutral0;
  border-top: 2px solid $colorNeutral20;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  .ant-select {
    display: none;
  }

  .registry-table__footer-number {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
    flex: 357 357;
    color: $colorNeutral50;

    .registry-table__footer-shown {
      @include body2Medium;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 4px;
    }

    .registry-table__footer-options {
      @include body2Medium;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 4px;
      flex: 136 136;

      &__pick {
        display: flex;
        align-items: center;
        color: $colorNeutral90;
        font-size: 14px;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        text-decoration-line: underline;
      }
    }
  }

  .ant-pagination {
    display: flex;
  }

  .ant-pagination-item {
    width: 32px;
    height: 32px;
    background: $colorNeutral0;
    border-radius: 10px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    @include body1Regular;
    color: $colorPrimaryDark;

    a {
      @include body1Regular;
      color: $colorPrimaryDark;
    }

    &:hover {
      background: $colorNeutral5;
      a {
        color: $colorPrimaryDark;
      }
    }

    &:active {
      background: $colorNeutral10;
      a {
        color: $colorPrimaryDark;
      }
    }

    &-active {
      background: $colorNeutral20;
      a {
        color: $colorPrimaryDark;
      }
      &:hover {
        background: $colorNeutral20;
      }
    }
  }
}
