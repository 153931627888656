// Таблица отчёта
.report-table {
  width: fit-content;
  table-layout: fixed;
  background-color: #fff;
  border-spacing: 4px !important;
  border-collapse: separate;
  margin-top: 20px;

  th,
  td {
    text-align: left;
    padding: 12px;
  }

  thead {
    th {
      background-color: #f4f4f4;
      font-weight: bold;
      font-size: 14px;
      color: #555;
      text-align: center;
    }
  }

  tbody {
    tr {
      &.category {
        & > td {
          background: #ffffff;
          font-weight: bold;
        }
      }

      &.subcategory {
        background: #f7f1ea;

        & > td {
          background: #f7f1ea;
          border: none;
        }
      }

      &:hover {
        background-color: #f5f5f5;
      }
    }

    td {
      font-family: "Inter";
      font-size: 14px;
      font-weight: 800;
      line-height: 16px;
      letter-spacing: -0.02em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

      &:first-child {
        border: none;
      }

      &:not(:first-child) {
        text-align: center;
        border: 1px solid #d7d7d7;
        border-radius: 4px;
      }
    }
  }
}

// .report-table th:first-child {
//   position: sticky;
//   left: 0;
//   background: #f5f5f5;
//   z-index: 1;
//   width: 300px; /* Установите фиксированную ширину */
//   overflow: hidden; /* Убедитесь, что текст не выходит за пределы */
//   text-overflow: ellipsis; /* Добавляет троеточие, если текст слишком длинный */
//   white-space: nowrap; /* Запрещает перенос текста */
// }

.planCell,
.factCell {
  padding: 2px 0 !important;
}

.tableHeader {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
}

.report-table {
  & .title {
    position: sticky;
    left: 0;
    z-index: 1;
    //width: 40%; /* Установите фиксированную ширину */
    overflow: hidden; /* Убедитесь, что текст не выходит за пределы */
    text-overflow: ellipsis;
    vertical-align: middle;
    //box-shadow: 2px 0px 20px 0px #0000001A;
  }
}

.subHeader {
  height: 13px;
  & > th {
    font-family: Inter;
    font-size: 13px !important;
    font-weight: 500 !important;
    line-height: 13px !important;
    //padding: 0px !important;
    letter-spacing: -0.02em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}

.expand-arrow {
  font-size: 16px; /* Размер стрелки */
  transition: transform 0.3s ease; /* Плавное изменение */
}

.expand-arrow.expanded {
  transform: rotate(
    90deg
  ); /* Поворачиваем стрелку на 90 градусов, когда раскрыто */
}
