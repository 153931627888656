@import "../styles.scss";

.reports {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  &__header {
    padding: 16px 20px;
    h2 {
      @include title3SemiBold;
      margin-bottom: 4px;
    }
  }
  &__navigate {
    margin: 16px 0 0;
    max-width: 80px;
    width: 100%;
    padding: 3px;
    display: flex;
    border-radius: 14px;
    background-color: $colorNeutral10;
    height: 42px;
    border: 1px solid $colorNeutral30;
    color: $colorNeutral80;

    a[data-title]::after {
      max-width: none;
      bottom: -18px;
    }
    &__divider {
      background-color: #d3d6dc;
      height: 16px;
      min-height: 16px;
      width: 1px;
      min-width: 1px;
      align-self: center;
    }
  }
}

@mixin reportsTooltip {
  position: absolute;
  @include tooltipRegular;
  top: 40px;
  background-color: $colorNeutral90;
  border-radius: 4px;
  padding: 2px 6px;
  color: $colorNeutral0;
  z-index: 200;
}
