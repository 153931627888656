@import "../../assets/styles/common.scss";

// Common

.standartNavItem,
.choosenStandartNavItem,
.strokeNavItem {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.sideNavItem,
.choosenSideNavItem,
.subSideNavItem,
.lastSubSideNavItem,
.choosenSubSideNavItem {
  width: 100%;
  height: 55px;
  padding-left: 65px;
  display: flex;
  align-items: center;
  align-self: flex-start;
  text-decoration: none;
  background-color: $colorNeutral0;
  text-decoration: none;
}

.subSideNavItem,
.choosenSubSideNavItem,
.lastSubSideNavItem {
  height: 40px;
}

.sideNavItem:hover,
.choosenSideNavItem:hover {
  background-color: $colorNeutral20;
  color: $colorNeutral100;
}

// Standart

.standartNavItem {
  border: none;
  color: $colorNeutral90;
  background-color: $colorNeutral10;
}

.choosenStandartNavItem {
  border: none;
  color: $colorNeutral0;
  background-color: $colorNeutral100;
}

.disabledReportsTab {
}

// Text

.textNavItem1 {
  text-decoration: none;
  color: $colorNeutral60;
}

.textNavItem1:hover {
  color: $colorNeutral70;
}

// Stroke

.strokeNavItem {
  border: 1px solid $colorNeutral100;
  color: $colorNeutral90;
  background-color: $colorNeutral0;
}

.strokeNavItem:hover {
  border: 1px solid $colorNeutral80;
  color: $colorNeutral80;
  background-color: $colorNeutral20;
}

// Sidebar

.sideNavItem,
.subSideNavItem,
.lastSubSideNavItem {
  color: $colorNeutral50;
}

.choosenSideNavItem,
.choosenSubSideNavItem {
  color: $colorNeutral90;
}

.subSideNavItem:hover,
.lastSubSideNavItem:hover {
  color: $colorNeutral90;
}

.navDashboard {
  background-image: url("../../assets/icons/svg/navdashboard.svg");
  background-position: 29px 50%;
  background-repeat: no-repeat;
}

.navDashboard:hover {
  background-image: url("../../assets/icons/svg/navdashboardfilled.svg");
}

.navDashboardFilled {
  background-image: url("../../assets/icons/svg/navdashboardfilled.svg");
  background-position: 29px 50%;
  background-repeat: no-repeat;
}

.navReport {
  background-image: url("../../assets/icons/svg/navreport.svg");
  background-position: 29px 50%;
  background-repeat: no-repeat;
}

.navReport:hover {
  background-image: url("../../assets/icons/svg/navreportfilled.svg");
}

.navReportfilled {
  background-image: url("../../assets/icons/svg/navreportfilled.svg");
  background-position: 29px 50%;
  background-repeat: no-repeat;
}

.navStaff {
  background-image: url("../../assets/icons/svg/navstaff.svg");
  background-position: 29px 50%;
  background-repeat: no-repeat;
}

.navStaff:hover {
  background-image: url("../../assets/icons/svg/navstafffilled.svg");
}

.navStaffFilled {
  background-image: url("../../assets/icons/svg/navstafffilled.svg");
  background-position: 29px 50%;
  background-repeat: no-repeat;
}

.navSpot {
  background-image: url("../../assets/icons/svg/navspot.svg"),
    url("../../assets/icons/svg/navarrowdown.svg");
  background-position:
    29px 50%,
    90% 50%;
  background-repeat: no-repeat;
}

.navSpot:hover {
  background-image: url("../../assets/icons/svg/navspotfilled.svg"),
    url("../../assets/icons/svg/navarrowdownfilled.svg");
}

.navSpotFilled {
  background-image: url("../../assets/icons/svg/navspotfilled.svg"),
    url("../../assets//icons/svg/navarrowupfilled.svg");
  background-position:
    29px 50%,
    90% 50%;
  background-repeat: no-repeat;
  color: $colorNeutral90;
}

.navSettings {
  background-image: url("../../assets/icons/svg/navsettings.svg");
  background-position: 29px 50%;
  background-repeat: no-repeat;
}

.navSettings:hover {
  background-image: url("../../assets/icons/svg/navsettingsfilled.svg");
}

.navSettingsFilled {
  background-image: url("../../assets/icons/svg/navsettingsfilled.svg");
  background-position: 29px 50%;
  background-repeat: no-repeat;
}

.navInfo {
  background-image: url("../../assets/icons/svg/information.svg");
  background-position: 29px 50%;
  background-repeat: no-repeat;
}

.navInfo:hover {
  background-image: url("../../assets/icons/svg/informationfilled.svg");
}

.navInfoFilled {
  background-image: url("../../assets/icons/svg/informationfilled.svg");
  background-position: 29px 50%;
  background-repeat: no-repeat;
}

// SubNav

.navSub {
  background-image: url("../../assets/icons/svg/navline.svg");
  background-position: 38px 50%;
  background-repeat: no-repeat;
}

.lastNavSub {
  background-image: url("../../assets/icons/svg/navlineend.svg");
  background-position: 38px 0px;
  background-repeat: no-repeat;
}
