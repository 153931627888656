@import "../../assets/styles/common.scss";

.onboarding-header {
  &__container {
    padding: 45px 40px;
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    width: 10%;
    margin-top: 10px;
  }

  &__main {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-header,
    &-spot,
    &-address {
      text-align: center;
    }

    &-header {
      margin-bottom: 24px;
      @include captionSemiBold;
      color: $colorNeutral100;
    }

    &-spot {
      margin-bottom: 2px;
      @include captionSemiBold;
      color: $colorAccent;
    }

    &-address {
      margin-bottom: 40px;
      @include body2Medium;
      color: $colorNeutral60;
    }

    &-steps {
      display: flex;

      &__step {
        width: 110px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-text {
          @include body2Regular;
          color: $colorNeutral100;
        }

        &-number {
          width: 44px;
          height: 44px;
          margin-bottom: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 22px;
          border: 3px solid $colorNeutral40;
          @include subtitleSemiBold;
          color: $colorNeutral40;

          &-active {
            width: 44px;
            height: 44px;
            margin-bottom: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 22px;
            background-color: $colorNeutral50;
            @include subtitleSemiBold;
            color: $colorNeutral0;
          }
        }
      }

      &__line {
        width: 74px;
        height: 1px;
        margin-top: 20px;
        border: 1px solid $colorNeutral30;
      }
    }
  }

  &__instruction {
    height: 23px;
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    width: 10%;

    &-logo {
      width: 21px;
      height: 21px;
      margin-right: 5px;
      background-image: url("../../assets/icons/svg/information.svg");
      background-size: contain;
      background-repeat: no-repeat;
    }

    &-text {
      @include body2Medium;
      color: $colorNeutral60;
    }
  }
}

.onboardingProcess__modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s;
  z-index: 100;

  &-active {
    opacity: 1;
    pointer-events: all;
    z-index: 100;
  }

  &-content {
    max-width: 440px;
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 24px;
    background-color: #ffffff;
    box-shadow:
      0px 2px 8px rgba(0, 0, 0, 0.03),
      0px 4px 20px rgba(0, 0, 0, 0.08);

    &__header {
      margin: 24px 0px;
      @include subtitleSemiBold;
      color: $colorNeutral100;
    }

    &__line {
      max-width: 440px;
      width: 100%;
      height: 2px;
      background-color: $colorNeutral30;
    }

    &__container {
      padding: 32px;

      &__point {
        margin-bottom: 24px;

        &:last-of-type {
          margin-bottom: 40px;
        }

        &-top {
          margin-bottom: 8px;
          display: flex;
        }

        &-number {
          width: 28px;
          height: 28px;
          display: flex;
          margin-right: 8px;
          justify-content: center;
          align-items: center;
          border-radius: 22px;
          background-color: $colorNeutral50;
          @include body1SemiBold;
          color: $colorNeutral0;
        }

        &-header {
          @include subtitleSemiBold;
          color: $colorNeutral100;
        }

        &-paragraph {
          @include body2Regular;
          color: $colorNeutral50;
        }
      }
    }
  }
}
