@import "assets/styles/common.scss";

.wrapper {
  margin-top: -8px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  font-family: "Inter";

  :global {
    .ant-upload-wrapper .ant-upload-drag {
      border-style: solid;

      &:hover {
        border-color: #b9b9b9;
      }
    }
  }
}

.subtitle {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;
}

.attachment {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  background-color: #f7f7f7;
  border-radius: 4px;
  padding: 8px 16px;

  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 2px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #272729;

    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #6b6d7e;
    }
  }

  svg {
    cursor: pointer;
    color: $colorNeutral60;
    &:hover {
      color: $colorNeutral80;
    }

    &:active {
      color: $colorNeutral100;
    }
  }
}

.upload-text {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: #a4a5b0;
}
