@import "../../assets/styles/common.scss";

.dropdown {
  position: relative;
  cursor: pointer;
  user-select: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $colorNeutral90;
  width: 100%;
  transition: 200ms linear;

  &.disabled {
    pointer-events: none;

    .dropdown-header {
      background: $colorNeutral10;
      border: 1px solid $colorNeutral30;
      color: $colorInactive;
    }
  }

  .dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 38px;
    background: $colorNeutral0;
    border: 1px solid $colorNeutral30;
    border-radius: 12px;
    padding: 7px 12px;
    transition: 200ms linear;

    &.placeholder {
      color: $colorInactive;
    }

    .text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 90%;
    }

    &:hover {
      border: 1px solid $colorNeutral60;
      background: $colorNeutral5;

      .dropdown-arrow {
        color: $colorNeutral90;
      }
    }

    &:active {
      border: 1px solid $colorNeutral80;
      background: $colorNeutral10;
    }

    // &.dropdown-header-active {

    // }

    .dropdown-arrow {
      color: $colorNeutral50;
      transition: 200ms ease-in-out;
      overflow: visible;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        transition: height 200ms linear;
      }

      &.down {
        transform: rotate(180deg);
      }
    }
  }

  .dropdown-list {
    position: absolute;
    width: 100%;
    z-index: 302;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    height: 0;
    overflow: hidden;
    background: $colorNeutral0;
    padding: 0;
    margin-top: 2px;
    border: 0 solid transparent;
    pointer-events: none;

    &.dropdown-list-active {
      height: auto;
      max-height: 220px;
      overflow-y: auto;
      border: 1px solid $colorNeutral30;
      pointer-events: all;
    }

    li {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 8px 9px;
      gap: 12px;
      width: 100%;
      height: 40px;
      background: $colorNeutral0;
      border-left: 3px solid $colorNeutral0;
      transition: 200ms ease-in-out;
      white-space: nowrap;
      padding-right: 10px;
      text-overflow: ellipsis;
      position: relative;


      &.active {
        background: $colorNeutral10;
        border-left: 3px solid $colorAccent;
      }

      &:hover {
        background: $colorNeutral10;
        border-left: 3px solid $colorAccent;
      }
    }
  }
}

.ant-dropdown-menu {
  padding: 0 !important;
}
