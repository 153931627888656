@import "../../styles.scss";

/* header */
.revenueHeader__title {
  padding-top: 16px;
  padding-bottom: 4px;
  @include body2SemiBold;
  color: $colorNeutral90;
}

.revenueHeader__description {
  padding-bottom: 16px;
  @include body2Regular;
  color: $colorNeutral50;
}

.revenueHeader__container {
  display: flex;
  justify-content: space-between;
  height: 56px;
}

.revenueHeader__calendar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 12px;
  gap: 12px;
  height: 38px;
  background: $colorNeutral0;
  border: 1px solid $colorNeutral20;
  border-radius: 12px;
  transition: 200ms ease-in-out;
  cursor: pointer;
  @include body2Regular;
  color: $colorNeutral90;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard syntax */

  .revenueHeader__calendar-icon {
    color: $colorNeutral50;
    transition: 200ms ease-in-out;
  }

  &:hover {
    border: 1px solid $colorNeutral100;

    .revenueHeader__calendar-icon {
      color: $colorNeutral100;
    }
  }

  &.revenueHeader__calendar_active {
    border: 1px solid $colorAccent;
  }
}

.revenuePlanning__wrapper {
  display: flex;
  flex-direction: column;
  min-height: 80%;
  max-width: 100%;
}

.revenuePlanning__header {
  padding: 20px;
  h2 {
    @include title3SemiBold;
    color: $colorNeutral90;
  }

  &__title {
    display: flex;
    padding-bottom: 20px;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    align-self: stretch;

    &__buttons {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      gap: 8px;
      flex: 1 1;
    }
  }

  &__desc {
    @include body2Regular;
    color: $colorNeutral50;
  }
}

.revenue-planning__underheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  .revenue-planning__info {
    display: flex;
    flex-direction: column;
    padding: 2px 10px;
    align-items: flex-end;

    border-radius: 8px;
    background: $colorInfoBg;
    @include captionMedium;
    color: $colorInfoFill;
    width: 353px;
    text-align: right;
  }
}

.revenuePlanning__summary-revenue {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;

  .title {
    @include body1SemiBold;
    color: $colorInactive;
  }

  @include title3Bold;
  color: $colorPrimaryDark;

  div {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .revenuePlanning__difference {
    p {
      display: flex;
      gap: 12px;
      align-items: center;
      @include body1SemiBold;
    }
  }
}

.revenuePlanning__detail-revenue {
  display: flex;
  gap: 16px;
  flex-flow: row wrap;
  padding-top: 16px;
  padding-bottom: 24px;
  p {
    width: 100%;
    @include body1SemiBold;
    color: $colorInactive;
  }

  .revenue-planing__detail-container {
    display: flex;
    align-items: flex-end;
    gap: 4px;

    .basicInput {
      display: flex;
      padding: 8px 12px 8px 19px;
      justify-content: center;
      align-items: center;
      @include body2Medium;
      color: $colorPrimaryDark;
    }
  }

  .revenue-planning__detail-percent {
    position: relative;
    &::after {
      content: "%";
      display: block;
      position: absolute;
      right: 12px;
      bottom: 8px;
      @include body2Medium;
      color: $colorInactive;
    }
  }
}

.revenuePlanning__container {
  padding: 0px 20px;
  border-bottom: 1px solid $colorNeutral30;
}

.revenuePlanning__main {
  flex: 1 1;
}
.revenuePlanning__tabel {
  padding: 16px 20px 16px 20px;

  p {
    @include body1SemiBold;
    color: $colorInactive;
    margin-bottom: 16px;
  }

  .react-calendar {
    min-width: 100%;
    box-shadow: none !important;
  }
}

.header-calendar__container {
  position: absolute;
  top: 190px;
  left: 296px;
  z-index: 30;
}

.header-calendar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  width: 236px;
  opacity: 0;
  pointer-events: none;
  background: $colorNeutral0;
  border: 1px solid $colorNeutral30;
  box-shadow:
    0px 2px 8px rgba(0, 0, 0, 0.03),
    0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  transition: 200ms ease-in-out;

  .react-calendar__navigation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 8px;
    gap: 8px;
    width: 210px;
    height: 36px;
  }

  .react-calendar__navigation__label {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 4px;
    width: 138px;
    height: 22px;
    background: transparent;
    border: none;

    span {
      @include body2Medium;
      display: flex;
      align-items: center;
      text-align: center;
      color: $colorNeutral100;
    }
  }

  .react-calendar__navigation__arrow {
    width: 28px;
    height: 28px;
    background-color: transparent;
    border: none;
    font-size: 0;
    background-image: url(../../../assets/icons/svg/Frame.svg);
    background-repeat: no-repeat;
    background-position: center;
  }

  .react-calendar__navigation__next-button {
    transform: rotate(180deg);
  }

  .react-calendar__navigation__next2-button,
  .react-calendar__navigation__prev2-button {
    display: none;
  }

  .react-calendar__year-view__months {
    gap: 2px;
  }

  .react-calendar__viewContainer {
    width: 210px;
  }

  .react-calendar__tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin: 0px 3px;
    gap: 8px;
    width: 45px;
    height: 30px;
    border: 1px solid transparent;
    border-radius: 8px !important;
    background: none;
    flex: initial !important;
    margin-bottom: 6px;
    transition: 200ms ease-in-out;

    &--active {
      color: $colorPrimaryDark;
    }

    abbr {
      @include body2Regular;
      @include body2Regular;
    }

    &:hover {
      background: $colorNeutral10;
    }

    &:active {
      background: $colorNeutral30;
    }
  }

  .react-calendar__tile--active {
    border: 1px solid $colorAccent;

    &:hover {
      background: $colorNeutral0;
    }
  }
}

.header-calendar_active {
  //height: 166px;
  opacity: 1;
  // z-index: 40;
  pointer-events: all;
}

.revenuePlanning__footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  padding: 0px 16px;
  width: 100%;
  height: 64px;
  border-top: 1px solid $colorNeutral30;
}

.skeleton {
  height: 650px;
}
