@import "../../assets/styles/common.scss";

.registry__filters-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 0px 8px;
  gap: 0px;
  transition: 200ms ease-in-out;

  .registry__filters__buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
  }

  .registry__filters-resetButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 12px;
    gap: 8px;
    border: none;
    border: 1px solid $colorNeutral20;
    background-color: $colorNeutral0;
    border-radius: 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    &:hover {
      border: 1px solid $colorNeutral30;
      background-color: $colorNeutral10;
    }

    &:active {
      border: 1px solid $colorNeutral40;
      background-color: $colorNeutral20;
    }
  }

  .registry__filters-button__vector {
    transform: rotate(0);
    transition: 200ms ease-in-out;
  }

  &.registry__filters-wrapper_active {
    gap: 8px;

    .registry__filters-button__vector {
      transform: rotate(180deg);
    }
  }

  .registry__filters-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    gap: 8px;

    width: 104px;
    height: 32px;

    /* Neutrals/Neutral 10 */
    background: $colorNeutral10;
    border-radius: 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border: none;

    &:hover {
      background: $colorNeutral20;
    }

    &:active {
      background: $colorNeutral30;
    }
  }

  .registry__filters-container {
    display: flex;
    gap: 4px;
    height: 0;
    opacity: 0;
    pointer-events: none;
    transition: 200ms ease-in-out;

    .ant-dropdown-trigger {
      background: $colorNeutral0;
      border: 1px solid $colorNeutral20;
      border-radius: 8px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px 4px 5px 8px;
      gap: 4px;
      letter-spacing: 0.15px;
      user-select: none;

      & div div span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 250px;
      }

      .ant-space {
        display: flex;
        align-items: center;

        .ant-space-item {
          display: flex;
          align-items: center;
        }
      }

      &:hover {
        background: $colorNeutral5;
      }

      &:active {
        background: $colorNeutral20;
        border: 1px solid $colorNeutral30;
      }
    }

    &.registry__filters-container_active {
      opacity: 1;
      pointer-events: all;
      height: 28px;
    }
  }
}
