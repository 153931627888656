@import "../../assets/styles/common.scss";

.confirm-alert {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 300;
  &-popup {
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    width: 440px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    background: $colorNeutral0;
    box-shadow:
      0px 4px 20px 0px rgba(0, 0, 0, 0.08),
      0px 2px 8px 0px rgba(0, 0, 0, 0.03);
    z-index: 301;

    &__header {
      display: flex;
      padding: 20px 20px 8px 20px;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      align-self: stretch;

      p {
        color: $colorPrimaryDark;
        @include subtitleSemiBold;
      }
      .exit {
        border: none;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 4px;
        gap: 8px;
        background: $colorNeutral10;
        border-radius: 8px;
        transition: 200ms ease-in-out;
        &:hover {
          background: $colorNeutral20;
        }
        &:active {
          background: $colorNeutral30;
        }
      }
    }

    &__body {
      display: flex;
      padding: 8px 20px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      p {
        @include body2Regular;
        color: $colorPrimaryDark;
      }
    }
    &__footer {
      display: flex;
      padding: 20px;
      justify-content: center;
      align-items: center;
      gap: 16px;
      align-self: stretch;
    }
  }

  &-overlay {
    background: rgba(44, 44, 44, 0.4);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
  }
}
