@import "../../assets/styles/common.scss";

// Variants

// Basic

.basicInput {
  width: 100%;
  padding: 12px;
  outline: none;
  border: 1px solid $colorStrokePrimary;
  border-radius: 4px;
  background-color: $colorPrimary;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: $colorTextPrimary;

  &::placeholder {
    color: $colorTextSecondary;
    opacity: 1;
  }

  &::-webkit-input-placeholder {
    color: $colorTextSecondary;
    opacity: 1;
  }

  &::-moz-placeholder {
    color: $colorTextSecondary;
    opacity: 1;
  }
}

.basicInput:focus {
  border-color: $colorTextSecondary;
}

.basicInput:disabled {
  border-color: $colorDisabled;
  color: $colorTextSecondary;
  background-color: $colorDisabled;
}

// search input

.searchInput {
  width: 100%;
  outline: none;
  border: 1px solid $colorNeutral40;
  background-color: $colorNeutral0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $colorNeutral90;
  padding: 6px 8px 6px 36px;
  background-image: url("../../assets/icons/svg/Search.svg");
  background-position: 8px 50%;
  background-repeat: no-repeat;

  &::placeholder {
    color: $colorInactive;
    opacity: 1;
  }

  &::-webkit-input-placeholder {
    color: $colorInactive;
    opacity: 1;
  }

  &::-moz-placeholder {
    color: $colorInactive;
    opacity: 1;
  }
}

.searchInput:focus {
  border-color: $colorNeutral100;
}

// Basic Error

.basicInputError {
  outline: none;
  border: 1px solid $colorErrorNew;
  background-color: $colorPrimary;
}

.basicInputError :focus {
  border-color: $colorErrorNew;
}

.inputField {
  position: relative;

  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 8px;

  padding-bottom: 19px;

  .label {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: left;

    color: $colorTextPrimary;

    &:has(+ input:disabled) {
      color: $colorTextSecondary;
    }
  }

  .errorText {
    position: absolute;
    bottom: 0;
    left: 0;

    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: -0.02em;
    text-align: left;
    color: $colorErrorNew;
  }
}

.resetButton {
  position: absolute;
  right: 10px;
  top: 4px;
  cursor: pointer;
}
