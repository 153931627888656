@import "../styles.scss";

.revenueModal__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 60;
  opacity: 0;
  gap: 8px;
  pointer-events: none;
  padding: 16px;
  width: 350px;
  height: 456px;
  background: $colorNeutral0;
  box-shadow:
    0px 2px 8px rgba(0, 0, 0, 0.03),
    0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  transition: 200ms ease-in-out;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard syntax */
}

.revenueModal__container_active {
  opacity: 1;
  pointer-events: all;
}

.revenueModal__header {
  display: flex;
  flex-direction: column;
  align-self: start;

  p {
    @include subtitleSemiBold;
    color: $colorNeutral90;
  }

  span {
    @include body2Regular;
    color: $colorNeutral50;
  }
}

.revenueModal__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0px;
  gap: 16px;
}

.revenueModal__close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px;
  gap: 8px;
  width: 28px;
  height: 28px;
  background: $colorNeutral0;
  color: $colorNeutral100;
  border-radius: 8px;
  cursor: pointer;
  transition: 200ms ease-in-out;

  &:hover {
    background: $colorNeutral20;
  }
}

.revenueModal__list-container {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .revenueModal__list-title {
    @include body2Medium;
    color: $colorNeutral50;
  }
}
