.dataTableHeaderCell {
    font-weight: 600;
    font-size: 12px;
    color: #98A1B2;
    padding: 12px 8px;
    border-right: 1px solid #d3d6db;
}

.dataTableHeaderCellContainer {
    display: flex;
    align-items: center;
    gap: 8px;
}

.sortButtonsContainer {
    display: flex;
    flex-direction: column;
}

.sortButton {
    &.down {
        transform: rotate(180deg);
    }
}

.sortButtonIcon {
    width: 10px;
    height: 10px;
    color: #98A1B2;

    &.active {
        color: black;
    }
}