@import "../styles.scss";

.init__wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.init__link {
  width: 80px;
  position: absolute;
  top: 20px;
  right: 20px;
}
