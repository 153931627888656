.date-bar__container {
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    text-transform: capitalize;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: -0.02em;
  }
}
