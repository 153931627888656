@import "../../assets/styles/common.scss";

.work-shits__wrapper {
  .linkButton {
    position: fixed;
    bottom: 32px;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    @include body2Regular;
  }
  .rbc-event-label {
    position: absolute;
    top: 29px;
    left: 6px;
    word-spacing: 4px;
  }

  .event-title {
    position: absolute;
    top: 5px;
    left: 6px;
  }

  .event-length {
    position: absolute;
    top: 29px;
    left: 94px;
    margin-left: 0 !important;
  }
}

// Сам ивент
.rbc-event,
.rbc-selected {
  padding: 5px 10px;
  border-radius: 12px;
  color: #ffffff;
  background-color: #6c737f;
  font-size: 16px;
  line-height: 24px;
  background-image: url("../../assets/icons/svg/pencil.svg");
  background-repeat: no-repeat;
  background-position: 99% 50%;
}

// Для рамки ивента
.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  border: none;
}

// Выбранный ивент
.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: #6c737f;
}

// Блоки с часами
.rbc-timeslot-group {
  min-width: 80px;
  min-height: 80px;
  // padding-top: 30px;
  // flex-flow: row nowrap;
}

// Тест времени
.rbc-timeslot-group .rbc-time-slot:first-child {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  color: #9da4ae;
}

// Полосы изменения размера
.rbc-addons-dnd
  .rbc-addons-dnd-resize-ns-anchor
  .rbc-addons-dnd-resize-ns-icon {
  display: none;
  border-top: 3px double;
  margin: 0px auto;
  width: 50px;
  cursor: ns-resize;
}

// отступ от края контейнера ивентов
.rbc-day-slot .rbc-events-container {
  margin-right: 60px;
}

// Время ивента
.rbc-addons-dnd-resizable .rbc-event-label {
  @include captionRegular;
}

// Текст ивента и длительность
.rbc-addons-dnd-resizable .rbc-event-content {
  @include body1Regular;
}

// Панель управления с кнопками и верхняя часть контейнера
.rbc-toolbar,
.rbc-addons-dnd-row-body,
.rbc-time-header .rbc-time-header-gutter {
  display: none;
}

// Верхняя рамка шкалы времени
.rbc-time-view {
  border-top: none;
}

.rbc-calendar:not(:first-child) .rbc-time-gutter {
  display: initial !important;
}

.rbc-time-header {
  padding-left: 80px;
  font-size: 16px;
  line-height: 24px;
}

.rbc-header {
  border: none;
  font-weight: normal;
}

.rbc-time-header-content > .rbc-row.rbc-row-resource {
  border-bottom: none;
}

.rbc-time-content {
  border-top: 1px solid #ddd;
}

//delete modal

.delete-modal {
  display: flex;
  flex-direction: column;
  padding: 0px;
  position: fixed;
  width: 440px;
  height: 176px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  z-index: 200;

  box-shadow:
    0px 2px 8px rgba(0, 0, 0, 0.03),
    0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 24px;

  &__main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 20px 8px;
    gap: 16px;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 16px;

      .title {
        @include subtitleSemiBold;
        color: $colorNeutral90;
      }

      .subtitle {
        @include body1Regular;
        color: $colorNeutral90;
      }
    }

    .closeButton {
      margin-top: 4px;
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
    gap: 16px;

    button {
      @include body2Regular;
    }

    .fillButton {
      background-color: $colorError;
      border-color: $colorError;
    }
  }
}

.overlay {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;

  /* Special/BG Overlay 40% */
  background: rgba(44, 44, 44, 0.4);
  width: 100vw;
  height: 100vh;
  z-index: 199;
  cursor: pointer;
}

.work-shifts__calendar {
  @include body1Regular;
  color: $colorNeutral90;
}
