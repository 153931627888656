.page {
    height: 100%;
    padding: 16px 20px;
}

.pageHeaderText {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #212129;
}

.pageHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.tabsContainer {
    margin-bottom: 32px;
}

.cellText {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #212129;
}

.expandContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 16px;
}

.closeLoanButton {
    max-width: fit-content;
    margin-left: auto;
}
