@import "../styles.scss";

.popupModal__element {
  .dropdown-header,
  .daypicker {
    @include body1Regular;
    color: $colorPrimaryDark;
  }

  .dropdown-header {
    border: 1px solid $colorNeutral30;
  }

  .daypicker {
    border-radius: 16px;
    height: 44px;
  }
  .calendar-modal__wrapper {
    z-index: -1;
    pointer-events: none;
    width: 400px;
  }
  .calendar-modal__wrapper_active.day {
    width: 400px;
    left: 20px !important;
    margin-top: 5px;
    z-index: 40;
    pointer-events: all;

    .react-calendar__month-view__weekdays {
      padding-left: 18px;
    }
  }

  .ant-dropdown-trigger {
    display: flex;
    padding: 10px 16px;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    align-self: stretch;
    max-width: 400px;
    border-radius: 16px;
    border: 1px solid $colorNeutral30;
    background: $colorNeutral0;
    height: 44px;
    @include body1Regular;
    color: $colorInactive;

    .ant-title {
      max-width: 350px;
      overflow: hidden;
      text-overflow: ellipsis;

      &.picked {
        color: $colorPrimaryDark;
      }
    }

    svg {
      transition: 200ms ease-in-out;
      min-width: 24px;
    }

    &.ant-dropdown-open {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.popupModal__element__category-dropdown {
  ul {
    max-height: 250px;
    overflow-y: scroll;
    width: 400px !important;
  }
  .ant-dropdown-menu {
    .title {
      display: flex;
      padding: 6px 8px;
      align-items: center;
      gap: 8px;
      align-self: stretch;

      @include captionMedium;
      color: $colorInactive;
    }

    li {
      @include body2Regular;
      color: $colorPrimaryDark;
    }
  }
}

.registry__wrapper {
  width: 100%;
  max-height: calc(100vh - 56px);
  height: 100%;

  overflow: auto;

  .registryHeader__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    width: 100%;
    padding: 16px 20px;

    .registryHeader__inner {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      gap: 16px;

      width: 100%;
      padding: 0px;
    }

    .registryHeader__title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
    }
  }

  .registry__main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;
    max-height: calc(100vh - 120px);
    height: 100%;
    padding: 16px 20px;

    .registry__underheader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 8px;

      padding: 0px 0px 8px;
      width: 100%;
    }

    .ant-space-item {
      color: $colorPrimaryDark;

      div {
        display: flex;
        align-items: center;
      }
    }

    .ant-dropdown-trigger {
      svg {
        transform: rotate(0);
        transition: 200ms ease-in-out;
      }

      &.ant-dropdown-open {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .registry-table {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      width: 100%;
      padding: 0px;

      border: 1px solid $colorNeutral20;
      border-radius: 10px;

      .registry-table__header {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        width: 100%;
        padding: 0px;

        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        .registry-table__header-element {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;

          height: 42px;
          padding: 12px 0px 12px 8px;

          color: $colorNeutral30;
          background: $colorNeutral5;
          border-bottom: 2px solid $colorNeutral20;
          user-select: none;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;

          &:first-child {
            border-top-left-radius: 10px;
          }
          &:last-child {
            border-top-right-radius: 10px;
          }
          &:nth-child(n + 3) {
            cursor: pointer;
            &:hover {
              color: $colorNeutral40;
              background: $colorNeutral20;
              border-bottom: 2px solid $colorNeutral30;
            }

            &:active {
              color: $colorNeutral50;
              background: $colorNeutral30;
              border-bottom: 2px solid $colorNeutral40;
            }
          }

          .registry-table__header-element-title {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 4px;

            padding: 0px;
            width: calc(100% - 5px);

            color: $colorNeutral60;

            svg {
              &.asc {
                path:last-child {
                  fill: $colorNeutral90;
                }
              }
              &.desc {
                path:first-child {
                  fill: $colorNeutral90;
                }
              }
            }
          }
        }
      }

      .registry-table__body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        width: 100%;
        padding: 0px;

        .registry-table__element {
          display: flex;
          flex-direction: row;
          align-items: flex-start;

          width: 100%;
          height: 44px;
          padding: 0px;

          background: $colorNeutral0;
          border-bottom: 1px solid $colorNeutral20;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          &:last-child {
            border-bottom: none;
          }

          cursor: pointer;

          .registry-table__element-cell {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 4px;

            height: 100%;
            padding: 12px 4px;
            white-space: nowrap;
            overflow: hidden;

            .registry-table__dot {
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              &::after {
                bottom: -26px;
                left: -5px;
              }
            }

            .svgWrapper {
              width: 20px;
              height: 20px;
              cursor: default;

              &[data-title]:after {
                bottom: initial;
                transform: none;
              }
            }

            &.inn {
              flex-direction: column;
              align-items: flex-start;
              justify-content: space-between;
              gap: 0;

              padding: 4px 4px;

              p {
                @include tooltipRegular;
                color: $colorNeutral60;
              }
            }

            &.registry-table__element-cell_center {
              justify-content: center;

              &:first-child {
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .registry__filters-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 0px;

      padding: 0px 0px 8px;
      transition: 200ms ease-in-out;

      .registry__filters__buttons {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 8px;

        padding: 0px;
      }

      .registry__filters-resetButton {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;

        padding: 5px 12px;

        background-color: $colorNeutral0;
        border: none;
        border: 1px solid $colorNeutral20;
        border-radius: 10px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        &:hover {
          border: 1px solid $colorNeutral30;
          background-color: $colorNeutral10;
        }

        &:active {
          border: 1px solid $colorNeutral40;
          background-color: $colorNeutral20;
        }
      }

      .registry__filters-button__vector {
        transform: rotate(0);
        transition: 200ms ease-in-out;
      }

      &.registry__filters-wrapper_active {
        gap: 8px;

        .registry__filters-button__vector {
          transform: rotate(180deg);
        }
      }

      .registry__filters-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;

        width: 104px;
        height: 32px;
        padding: 6px 12px;

        /* Neutrals/Neutral 10 */
        background: $colorNeutral10;
        border-radius: 10px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        border: none;

        &:hover {
          background: $colorNeutral20;
        }

        &:active {
          background: $colorNeutral30;
        }
      }

      .registry__filters-container {
        display: flex;
        gap: 4px;

        height: 0;

        opacity: 0;
        pointer-events: none;
        transition: 200ms ease-in-out;

        .ant-dropdown-trigger {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;

          padding: 5px 4px 5px 8px;

          background: $colorNeutral0;
          border: 1px solid $colorNeutral20;
          border-radius: 8px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 14px;
          letter-spacing: 0.15px;
          user-select: none;

          & div div span {
            max-width: 250px;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .ant-space {
            display: flex;
            align-items: center;

            .ant-space-item {
              display: flex;
              align-items: center;
            }
          }

          &:hover {
            background: $colorNeutral5;
          }

          &:active {
            background: $colorNeutral20;
            border: 1px solid $colorNeutral30;
          }
        }

        &.registry__filters-container_active {
          height: 28px;

          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }

  .f28 {
    width: 28px;
  }

  .f130 {
    flex: 130 130;
  }

  .f200 {
    flex: 200 200;
  }

  .f180 {
    flex: 180 180;
  }

  .f232 {
    flex: 232 232;
  }
}

.ant-dropdown {
  .react-calendar {
    max-width: 320px;

    background: white;
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;

    &--doubleView {
      width: 700px;

      .react-calendar__viewContainer {
        display: flex;

        margin: -0.5em;
      }

      .react-calendar__viewContainer > * {
        width: 50%;
        margin: 0.5em;
      }
    }

    &,
    & *,
    & *:before,
    & *:after {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }

    button {
      margin: 0;
      border: 0;
      outline: none;
    }

    button:enabled:hover {
      cursor: pointer;
    }

    &__navigation {
      display: flex;

      height: 44px;
      margin-bottom: 1em;

      button {
        min-width: 44px;
        background: none;
      }

      button:disabled {
        background-color: #f0f0f0;
      }

      button:enabled:hover,
      button:enabled:focus {
        background-color: #e6e6e6;
      }
    }

    &__month-view__weekdays {
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.75em;
    }

    &__month-view__weekdays__weekday {
      padding: 0.5em;
    }

    &__month-view__weekNumbers .react-calendar__tile {
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 0.75em;
      font-weight: bold;
    }

    &__month-view__days__day--weekend {
      color: #d10000;
    }

    &__month-view__days__day--neighboringMonth {
      color: #757575;
    }

    &__year-view .react-calendar__tile,
    &__decade-view .react-calendar__tile,
    &__century-view .react-calendar__tile {
      padding: 2em 0.5em;
    }

    &__tile {
      max-width: 100%;
      padding: 10px 6.6667px;

      background: none;
      text-align: center;
      line-height: 16px;

      &:disabled {
        background-color: #f0f0f0;
      }

      &:enabled:hover,
      &:enabled:focus {
        background-color: #e6e6e6;
      }

      &--now {
        background: #ffff76;
        &:enabled:hover,
        &:enabled:focus {
          background: #ffffa9;
        }
      }

      &--hasActive {
        background: #76baff;

        &:enabled:hover,
        &:enabled:focus {
          background: #a9d4ff;
        }
      }

      &--active {
        background: #006edc;
        color: white;

        &:enabled:hover,
        &:enabled:focus {
          background: #1087ff;
        }
      }
    }

    &--selectRange &__tile--hover {
      background-color: #e6e6e6;
    }

    border: none;
  }
}

.ant-dropdown-menu {
  background: $colorNeutral0;
  border: 1px solid $colorNeutral20;
  box-shadow: 0px 1px 3px rgba(77, 77, 77, 0.2);
  border-radius: 8px;

  &.contragents {
    width: auto;
    overflow-y: scroll;
    overflow-x: auto;
    max-height: 400px;

    white-space: nowrap;
    .ant-dropdown-menu-title-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 40px;
    }

    span.inn {
      @include captionRegular;
      color: $colorNeutral40;
    }
  }
}

.registry-modal__error {
  width: 100%;
  padding: 8px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .message {
    @include body1Regular;
  }
}
