@import "assets/styles/common.scss";

.schedule-templates__empty-layout-container {
  margin-top: 157px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 24px;

  h2 {
    color: $colorTextPrimary;

    font-size: 32px;
    font-weight: 800;
    letter-spacing: -0.02em;
  }

  button {
    min-width: 141px;
  }
}

.schedule-templates__list {
  display: flex;
  flex-direction: column;
  align-items: start;

  margin: 24px 0;

  gap: 16px;
}

.template {
  display: flex;
  align-items: start;
  gap: 16px;

  & > * {
    width: 100%;
  }

  border: 1px solid $colorStrokePrimary;
  border-radius: 8px;

  padding: 16px;

  width: 508px;

  & > * {
    width: 100%;
  }

  .duration {
    display: grid;
    place-content: center;

    min-width: 60px;
    max-width: 60px;
    height: 40px;

    border-radius: 4px;

    font-size: 14px;
    font-weight: 800;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: center;
    color: $colorTextPrimary;
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 6px;

    h6 {
      color: $colorTextPrimary;
      font-size: 16px;
      font-weight: 800;
      line-height: 20px;
      letter-spacing: -0.01em;
      text-align: left;
    }

    .time {
      color: $colorTextPrimary;

      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.02em;
    }

    width: 400px;

    .description {
      color: $colorTextSecondary;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.02em;
      text-align: left;

      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .buttons {
    display: flex;
    align-items: center;

    gap: 12px;

    button {
      outline: none;
      cursor: pointer;

      display: grid;
      place-content: center;

      width: 16px;
      height: 16px;

      background-color: transparent;
      border: none;
    }
  }
}

.row {
  display: flex;
  justify-content: space-between;

  width: 400px;
  gap: 5px;

  h6 {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.form {
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 5px;
}

.buttons-group {
  align-self: end;

  button {
    min-width: 110px;
  }
}

.select-row-palette {
  width: max-content !important;
  :global {
    .ant-select {
      &-item {
        padding: 0 !important;
        width: 16px !important;
        height: 16px !important;
        min-height: 0 !important;
      }
    }
    .rc-virtual-list-holder {
      &-inner {
        display: grid !important;
        grid-template-columns: 16px 16px 16px 16px 16px 16px 16px 16px 16px !important;
        gap: 10px !important;
        justify-items: center !important;
        padding: 10px;
      }
    }
  }
}
