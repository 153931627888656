@import "../../../assets/styles/common.scss";

.period-picker {
  position: relative;
  display: block;

  left: 0;
  z-index: 20;

  &__period {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: fit-content;
    padding: 9px 7px;
    gap: 4px;

    border: 1px solid $colorNeutral20;
    border-radius: 8px;
    background-color: $colorNeutral0;
    cursor: pointer;

    &--filter {
      padding: 5px 7px !important;
    }

    p {
      @include tagRegular;
    }
  }
  &__calendar-wrapper {
    position: absolute;
    top: 39px;
    left: 0;
    &--filter {
      top: 32px;
    }
  }
}
