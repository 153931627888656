@import "../styles.scss";

.not-found__wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;

    p {
      @include title3SemiBold;
    }

    button {
      margin-right: 37px;
    }
  }
}
