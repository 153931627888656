.modalWrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 426px;
    padding: 40px !important;
}

.modalTitleContainerText {
    font-weight: 800;
    font-size: 24px;
    line-height: 24px;
    max-width: 312px;
}

.modalDescriptionContainer {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.modalButtons {
    display: flex;
    justify-content: center;
    gap: 16px;
}
