@import "assets/styles/common.scss";

.text-button {
  outline: none;
  cursor: pointer;

  border: none;
  background-color: transparent;

  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: center;

  color: $colorTextHighlight;
}
