@import "../../assets/styles/common.scss";

.month-calendar {
  background: $colorNeutral0;
  border: 1px solid $colorNeutral20;
  border-radius: 12px;
  transition: 200ms ease-in-out;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard syntax */

  width: 250px;
  overflow: hidden;

  box-shadow:
    0 4px 20px 0 rgba(0, 0, 0, 0.08),
    0 2px 8px 0 rgba(0, 0, 0, 0.03);
  .react-calendar__navigation__label {
    background-color: #fff !important;
    color: $colorNeutral90;
  }

  .react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
  }

  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    border: none;
    outline: none;
    border-radius: 8px;
  }

  .react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }

  .react-calendar__navigation {
    display: flex;
  }
  .react-calendar__year-view__months__month {
    border: none;
    outline: none;
  }

  &_active {
    border: 1px solid $colorAccent;
  }

  .react-calendar__year-view {
    .react-calendar {
      background: white;
      border: 1px solid #a0a096;
      font-family: Arial, Helvetica, sans-serif;
      line-height: 1.125em;
    }

    .react-calendar button:enabled:hover {
      cursor: pointer;
    }

    .react-calendar__year-view .react-calendar__tile,
    .react-calendar__decade-view .react-calendar__tile,
    .react-calendar__century-view .react-calendar__tile {
      padding: 2em 0.5em;
    }

    .react-calendar__tile {
      @include body2Regular;
      max-width: 100%;
      padding: 10px 6.6667px;
      background: none;
      text-align: center;
      line-height: 16px;
      border-radius: 8px;
      &:disabled {
        background-color: #f0f0f0;
      }
      &:hover {
        background-color: #e6e6e6;
      }

      &--range {
        color: $colorNeutral0;
        &End {
          border-radius: 0 8px 8px 0 !important;
        }
        &Start {
          border-radius: 8px 0 0 8px !important;
        }
        &BothEnds {
          border-radius: 8px !important;
        }
      }
    }

    .react-calendar__tile:disabled {
    }

    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
    }

    .react-calendar__tile--now {
      @include body2SemiBold;
    }

    .react-calendar__tile--hasActive {
      background: #76baff;
    }

    .react-calendar__tile--hasActive:enabled:hover,
    .react-calendar__tile--hasActive:enabled:focus {
      background: #a9d4ff;
    }

    .react-calendar__tile--active {
      background: #006edc;
      color: white;
    }

    .react-calendar--selectRange .react-calendar__tile--hover {
      background-color: #44a9c2;
    }

    .react-calendar__year-view__months .react-calendar__tile--active {
      background-color: #44a9c2;
      border: none;
      color: white;
    }

    .react-calendar,
    .react-calendar *,
    .react-calendar *:before,
    .react-calendar *:after {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }

    .react-calendar button {
      margin: 0;
      border: 0;
      outline: none;
    }
  }
}
