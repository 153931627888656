@import "../styles.scss";

.dashboard {
  width: 100%;
  padding: 40px 20px 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  &__title {
    @include title3SemiBold;
    color: $colorNeutral90;
    font-weight: 800;
    font-size: 32px;
    margin-bottom: 16px;
  }

  &__options {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: space-between;
    align-items: start;
    // margin-bottom: 200px;
    .item-period-select {
      position: relative;
      &__field {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        gap: 4px;

        width: 110px;
        padding: 9px 8px;

        border-radius: 8px;
        border: 1px solid $colorNeutral20;
        cursor: pointer;
        p {
          @include tagRegular;
        }
      }
      &__dropdown {
        position: absolute;
        right: -40px;
        &--closed {
          display: none;
        }
        .dropdown-wrapper {
          z-index: 999;
          position: relative;
          padding: 4px 0;
          border-radius: 8px;
          border: 1px solid $colorNeutral20;
          box-shadow: 0px 1px 3px 0px rgba(77, 77, 77, 0.2);
          background-color: #fff;
          z-index: 20;
          margin-top: 4px;
          min-width: 150px;
        }

        &__title {
          @include captionMedium;
          color: $colorInactive;
          padding: 8px;
        }
        &__item {
          @include body2Regular;
          padding: 6px 8px;
          cursor: pointer;
        }
      }
    }
  }
  &__chart {
    display: flex;
    flex-flow: column wrap;
    gap: 28px;

    width: 100%;
    padding: 16px 32px 16px 16px;

    overflow: hidden;

    border: 1px solid $colorNeutral20;
    border-radius: 16px;

    .active-date {
      outline: none;
      cursor: pointer;

      padding: 7px 8px;
      display: flex;
      align-items: center;
      width: fit-content;
      gap: 4px;
      background-color: $colorNeutral5;
      border: 1px solid $colorNeutral20;
      border-radius: 8px;

      margin-bottom: 8px;
      @include tagRegular;
      color: $colorPrimaryDark;
    }

    .chart {
      &__header {
        display: flex;
        justify-content: flex-end;

        .chart-header {
          &__title {
            @include subtitleSemiBold;
          }
          &__legend {
            display: flex;
            flex-flow: row wrap;
            gap: 24px;
            .legend__item {
              cursor: pointer;

              display: flex;
              flex-flow: row wrap;
              align-items: center;
              gap: 6px;
              p {
                @include captionRegular;
                color: $colorNeutral80;
              }
              span {
                display: block;
                width: 16px;
                height: 16px;
                border-radius: 4px;
                border: 1px solid #892dff;
              }
              &:last-of-type {
                span {
                  border: 1px solid #70b6f6;
                }
              }
            }
          }
        }
      }
      &__container {
        height: 100%;
        height: 400px;

        .recharts-cartesian-axis-tick {
          * {
            fill: $colorInactive;
            font-family: Inter;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0px;
            text-align: right;
          }
        }
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border: 2px solid #e6e6e6;
    border-radius: 25px;
    width: 220px;
    height: 150px;

    padding: 16px;

    font-family: Inter;

    h3 {
      @include subtitleSemiBold;
    }

    p {
      @include subtitleSemiBold;
      font-weight: 500;
      text-align: right;
    }

    &--container {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
}

.info {
  &-purple {
    color: #892dff;
  }
  &-blue {
    color: #70b6f6;
  }
  &-green {
    color: #75b224;
  }
  &-red {
    color: #e35656;
  }
}

.chart-tooltip {
  padding: 5px;
  background-color: $colorNeutral90;
  color: white;
  border-radius: 4px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;

  &__fixed--right {
    position: relative;
    left: 10px;
  }
  &__fixed--left {
    position: relative;
    right: calc(100% + 10px) !important;
  }
  .tooltip-count {
    color: #c598ff;
  }
  .tooltip-average {
    color: #70b6f6;
  }
  // left: 25px;
}

.recharts-tooltip-wrapper {
  right: 0 !important;
  height: auto !important;
  width: fit-content !important;
}

.x-axis-tick--holiday {
  fill: $colorNeutral90 !important;
}
