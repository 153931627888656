@import "assets/styles/common.scss";

.template-empty {
  display: flex;
  align-items: center;
  gap: 16px;

  & > * {
    width: 100%;
  }

  border: 1px solid $colorStrokePrimary;
  border-radius: 8px;

  padding: 16px;

  width: 508px;

  & > * {
    width: 100%;
  }

  .duration {
    min-width: 60px;
    max-width: 60px;
    height: 40px;

    background-color: #d9d9d9;
    border-radius: 4px;
  }

  .info {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: left;

    color: $colorTextSecondary;
  }
}
