@import "./fonts.scss";
@import "./colors.scss";

.colorNeutral0 {
  color: $colorNeutral0;
}
.colorNeutral5 {
  color: $colorNeutral5;
}
.colorNeutral10 {
  color: $colorNeutral10;
}
.colorNeutral20 {
  color: $colorNeutral20;
}
.colorNeutral30 {
  color: $colorNeutral30;
}
.colorNeutral40 {
  color: $colorNeutral40;
}
.colorNeutral50 {
  color: $colorNeutral50;
}
.colorNeutral60 {
  color: $colorNeutral60;
}
.colorNeutral70 {
  color: $colorNeutral70;
}
.colorNeutral80 {
  color: $colorNeutral80;
}
.colorNeutral90 {
  color: $colorNeutral90;
}
.colorNeutral100 {
  color: $colorNeutral100;
}

.colorPrimaryDark {
  color: $colorPrimaryDark;
}
.colorPrimaryLight {
  color: $colorPrimaryLight;
}
.colorSecondary {
  color: $colorSecondary;
}
.colorInactive {
  color: $colorInactive;
}
.colorAccent {
  color: $colorAccent;
}

.colorAccentSecondary {
  color: $colorAccentSecondary;
}
.colorError {
  color: $colorError;
}
.colorWarning {
  color: $colorWarning;
}
.colorSuccess {
  color: $colorSuccess;
}

%subtitleSemiBold {
  font-family: "Fira Sans SemiBold";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
}

.subtitleSemiBold {
  @extend %subtitleSemiBold;
}

/* Body 1 */

%body1Regular {
  font-family: $interFont;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.body1Regular {
  @extend %body1Regular;
}

%body1Medium {
  font-family: $interFont;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.body1Medium {
  @extend %body1Medium;
}

%body1SemiBold {
  font-family: $interFont;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.body1SemiBold {
  @extend %body1SemiBold;
}

/* Body 2 */
%body2Regular {
  font-family: $interFont;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.body2Regular {
  @extend %body2Regular;
}

%body2Medium {
  font-family: $interFont;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.body2Medium {
  @extend %body2Medium;
}

%body2Bold {
  font-family: $interFont;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.body2Bold {
  @extend %body2Bold;
}

/* Captions 1 */
%captionRegular {
  font-family: $interFont;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
}

.captionRegular {
  @extend %captionRegular;
}

%captionMedium {
  font-family: "Fira Sans Medium";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
}

.captionMedium {
  @extend %captionMedium;
}

%captionSemiBold {
  font-family: "Fira Sans Medium";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
}

.captionSemiBold {
  @extend %captionSemiBold;
}

.button-primary,
.button-secondary {
  outline: none;
  cursor: pointer;

  padding: 12px;

  border: 1px solid $colorStrokePrimary;
  border-radius: 4px;

  font-size: 14px;
  font-weight: 500;
  color: $colorTextPrimary;

  transition: all 0.05s ease;

  text-align: center;
  font-style: normal;
  letter-spacing: -0.02em;

  &:active {
    box-shadow: 0px 0px 100px -4px rgba(0, 0, 0, 0.17) inset;
  }
}

.button-primary {
  border-color: $colorStrokeHighlightPrimary;
  background-color: $colorHighlightPrimary;
}

.button-secondary {
  background-color: $colorPrimary;
}
