//Neutral
$colorNeutral0: #ffffff;
$colorNeutral5: #f9f9fa;
$colorNeutral10: #f1f2f4;
$colorNeutral20: #e3e5e8;
$colorNeutral30: #d3d6db;
$colorNeutral40: #b4bac6;
$colorNeutral50: #a6aebb;
$colorNeutral60: #98a1b2;
$colorNeutral70: #8b93a7;
$colorNeutral80: #414152;
$colorNeutral90: #212129;
$colorNeutral100: #0c0c0d;

//Text & Icon
$colorPrimaryDark: #272729;
$colorPrimaryLight: #ffffff;
$colorSecondary: #6b6d7e;
$colorInactive: #a4a5b0;

//Primary
$colorPrimaryDefault: #44a9c2;
$colorPrimaryHover: #409fb7;
$colorPrimaryPress: #3c95ab;

//Secondary
$colorSecondaryDefault: #dfd4a6;
$colorSecondaryHover: #d3c99d;
$colorSecondaryPress: #c8be95;

//Status
$colorAccent: #44a9c2;
$colorAccent15: #44a9c226;
$colorAccent35: #44a9c259;
$colorAccentSecondary: #d9d0ad;
$colorError: #e35656;
$colorErrorBg: rgba(238, 76, 62, 0.12);
$colorErrorFill: #ee4c3e;
$colorWarning: #f2cb30;
$colorSuccess: #75b324;
$colorSuccessFill: #75b224;
$colorInfoFill: #70b6f6;
$colorInfoBg: rgba(112, 182, 246, 0.12);
$colorInfoBgHover: rgba(112, 182, 246, 0.24);

//NEW

//Text+Icons
$colorTextPrimary: #000000;
$colorTextSecondary: #999999;
$colorTextLight: #ffffff;
$colorTextHighlight: #6d37f6;

//Background
$colorPrimary: #ffffff;
$colorSecondary: #000000;
$colorHighlightPrimary: #caf711;
$colorHighlightSecondary: #6d37f6;

$colorDisabled: #f7f7f7;
$colorErrorNew: #eb5757;

//Stroke
$colorStrokePrimary: #d9d9d9;
$colorStrokeHighlightPrimary: #caf711;
$colorStrokeHighlightSecondary: #6d37f6;

:export {
  colorInfoFill: $colorInfoFill;
  colorNeutral0: $colorNeutral0;
  colorNeutral30: $colorNeutral30;
  colorError: $colorErrorNew;
}
