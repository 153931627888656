@import "../../styles.scss";

.mainProcess__wrapper {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.mainProcess__inner {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: visible;

  // new
  height: calc(100vh - 56px);
}

.mainProcess__container {
  width: calc(100% - 280px);

  // new
  overflow-y: auto;
  // scrollbar-gutter: stable;

  height: 100%;
  // overflow-x: hidden;
}
