.listItem {
    display: flex;
    align-items: center;
    gap: 16px;
    border: 1px solid rgba(217, 217, 217, 1);
    padding: 32px;
}

.listItemIndex {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    border: 1px solid rgba(217, 217, 217, 1);
}

.listItemTitle {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.listItemTitleText {
    max-width: 70%;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
}

.listItemButton {
    min-width: 167px;
    margin-left: auto;
}

.successfully {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    padding: 12px 0;
    min-width: 167px;
    margin-left: auto;
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 4px;
    color: rgba(109, 55, 246, 1);
}

.accepted {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    padding: 12px 0;
    min-width: 167px;
    margin-left: auto;
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 4px;
    color: rgba(109, 55, 246, 1);
}

.failed {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    padding: 12px 0;
    min-width: 167px;
    margin-left: auto;
    border: 1px solid rgba(235, 87, 87, 1);
    border-radius: 4px;
    color: black;
}

.icon {
    width: 16px;
    color: rgba(235, 87, 87, 1);
}

.successIcon {
    width: 16px;
    color: rgba(109, 55, 246, 1);
}