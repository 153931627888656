.container {
  z-index: 1;

  display: flex;
  gap: 3px;
  align-items: center;
  background-color: #f7f7f7;

  box-shadow: 2px 0px 20px 0px #0000001a;

  th {
    flex-shrink: 0;
    width: 60px;
    height: 54px;

    display: flex;
    align-items: center;
  }
}
