@import "assets/styles/common.scss";

.schedule__wrapper {
  display: flex;
  flex-direction: column;

  padding-right: 24px;

  position: relative;

  overflow-x: scroll;
  overflow-y: auto;

  margin-top: 16px;

  height: calc(100vh - 203px);

  gap: 24px;
}

.add-helper-button {
  margin-top: 16px;
  outline: none;
  cursor: pointer;

  border: none;
  background-color: transparent;

  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: center;

  color: $colorTextHighlight;
}

.schedule-table {
  border-collapse: separate;
  border-spacing: 3px;

  width: 100%;
  table-layout: fixed;

  th:nth-child(1) {
    font-size: 15px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: left;
    padding: 12px;
  }

  td {
    height: 40px;
    max-height: 40px;

    box-sizing: border-box;

    text-align: center;
    vertical-align: middle;

    border-radius: 4px;
    border: 1px solid $colorStrokePrimary;

    &:nth-child(1) {
      text-align: left;
      border: none;
    }
  }

  .column-time,
  .column-sum {
    border: none;

    font-size: 14px;
    font-weight: 800;
  }
}

.schedule-table__th {
  height: 54px;

  text-align: center;
  vertical-align: middle;
  background-color: #f7f7f7;

  font-family: "Inter";
  font-size: 15px;
  font-weight: 700;
  letter-spacing: -0.02em;

  border-radius: 4px;
}

.th-day {
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;

  p {
    font-size: 13px;
    font-weight: 800;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: center;
  }

  span {
    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: -0.02em;
    text-align: center;

    color: $colorTextSecondary;
  }
}

.weekend {
  background-color: #f4fdcf;
}

.th-time {
  background: url("../../../../assets/icons/svg/schedule/time_icon_16px.svg")
    no-repeat center;
  background-color: #333333;
}

.th-sum {
  background: url("../../../../assets/icons/svg/schedule/count_icon_16px.svg")
    no-repeat center;
  background-color: #333333;
}

.employees-container {
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-top: 3px;

  & > * {
    flex-shrink: 0;
    flex-grow: 1;
  }

  width: calc(3px + 268px);
}

.employees-wrapper {
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 27px;

  margin-top: 3px;
  background-color: white;
  box-shadow: 5px 0px 20px 0px #0000001a;

  .employees-container:nth-child(1) {
    margin-top: 0;
    margin-bottom: 3px;
  }
}
.hidden {
  visibility: hidden;
  z-index: -1;
}

.thead-employee {
  height: 54px;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;

  p {
    text-align: center;
    vertical-align: middle;
    font-family: Inter;
    font-size: 15px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.02em;
  }

  padding: 12px;
  font-family: Inter;
  font-size: 15px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.02em;

  border-radius: 4px;
}

.column-employee {
  cursor: default;

  display: grid;
  grid-template-columns: 140px 116px;
  justify-content: space-between;
  align-items: start;

  height: 100%;
  min-height: 40px;
  max-height: 40px;
  overflow: hidden;

  padding: 12px 6px 12px 12px;

  & > strong {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: -0.02em;
    margin-right: 8px;
  }

  .job-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > span {
      font-family: "Inter";
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.02em;

      text-align: left;

      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;

      color: $colorTextSecondary;
    }

    & > button {
      outline: none;
      cursor: pointer;
      display: grid;
      place-content: center;

      width: 16px;
      height: 16px;

      background-color: transparent;
      border: none;
    }
  }
}

.timeline {
  position: sticky;
  z-index: 3;
  margin-left: 3px;
  bottom: 0;

  align-self: stretch;
  background-color: white;
  box-shadow: 2px 0px 20px 0px #0000001a;

  display: flex;
  align-items: center;

  gap: 3px;

  border-radius: 4px;
  width: max-content;

  &__title {
    position: sticky;
    left: -1px;

    z-index: 3;
    background-color: white;

    display: flex;
    align-items: center;
    width: 268px;

    padding: 12px 0 12px 12px;

    gap: 8px;

    p {
      font-size: 14px;
      font-weight: 800;
      line-height: 16px;
      letter-spacing: -0.02em;
    }
  }

  .time-sticky {
    padding-left: 13px;
    box-shadow: 5px 0px 20px 0px #0000001a;
    width: calc(268px + 3px + 1px);
  }

  &__time {
    width: 60px;
    text-align: center;

    font-size: 14px;
    font-weight: 800;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: center;

    padding: 12px 0;
  }
}
