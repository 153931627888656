@import "../../styles.scss";

.invent-modal__picked-responsibles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;

  &__item {
    display: flex;
    padding: 8px 12px;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    background: $colorNeutral10;
    width: 100%;
    @include body2Regular;
    color: $colorSecondary;

    svg {
      cursor: pointer;
    }

    &.delete {
      background: #fde9e7;
    }

    &-title {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .reason {
        @include captionRegular;
        color: $colorErrorFill;
      }

      .name {
      }
    }
  }
}

.invent-modal__dropdown-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px;
  gap: 12px;
  width: 100%;
  background: $colorNeutral0;
  border-left: 3px solid $colorNeutral0;
  transition: 200ms ease-in-out;

  .checkbox {
    height: 24px;
  }

  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;

    span {
      @include captionRegular;
      color: $colorErrorFill;
    }
  }

  &:hover {
    background: $colorNeutral10;
    border-left: 3px solid $colorAccent;
  }
}
