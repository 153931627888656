@import "../../assets/styles/common.scss";

// Variants

// Basic

.basicInput {
  width: 100%;
  padding: 0px 16px;
  outline: none;
  border: 1px solid $colorNeutral40;
  background-color: $colorNeutral0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $colorNeutral90;

  &::placeholder {
    color: $colorInactive;
    opacity: 1;
  }

  &::-webkit-input-placeholder {
    color: $colorInactive;
    opacity: 1;
  }

  &::-moz-placeholder {
    color: $colorInactive;
    opacity: 1;
  }
}

.basicInput:focus {
  border-color: $colorNeutral100;
}

.basicInput:disabled {
  border-color: $colorNeutral30;
  color: $colorNeutral50;
  background-color: $colorNeutral20;
}

// search input

.searchInput {
  width: 100%;
  outline: none;
  border: 1px solid $colorNeutral40;
  background-color: $colorNeutral0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $colorNeutral90;
  padding: 6px 8px 6px 36px;
  background-image: url("../../assets/icons/svg/Search.svg");
  background-position: 8px 50%;
  background-repeat: no-repeat;

  &::placeholder {
    color: $colorInactive;
    opacity: 1;
  }

  &::-webkit-input-placeholder {
    color: $colorInactive;
    opacity: 1;
  }

  &::-moz-placeholder {
    color: $colorInactive;
    opacity: 1;
  }
}

.searchInput:focus {
  border-color: $colorNeutral100;
}

// Basic Error

.basicInputError {
  width: 100%;
  padding: 0px 16px;
  outline: none;
  border: 1px solid $colorError;
  color: $colorError;
  background-color: $colorNeutral0;
}

.basicInputError :focus {
  border-color: $colorError;
}

.basicInputError::placeholder {
  color: $colorError;
}

.inputField {
  position: relative;
  width: 100%;
}

.resetButton {
  position: absolute;
  right: 10px;
  top: 4px;
  cursor: pointer;
}
