.wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.inputWrapper {
    position: relative;
}

.labelInside {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 6px;
    color: rgba(153, 153, 153, 0.5);
}

.input {
    padding: 12px 23px;
    font-size: 14px;
    outline: none;
    border: 1px solid rgba(153, 153, 153, 0.5);
    border-radius: 4px;
}