@import "assets/styles/common.scss";

.schedule-planning {
  &__wrapper {
    width: 100%;
    max-height: calc(100vh - 56px);
    height: 100%;

    padding: 40px 0 0;

    overflow: auto;

    font-family: "Inter";
  }

  &__header {
    display: flex;

    padding: 0 24px;

    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 32px;
      font-weight: 800;
      line-height: 40px;
      letter-spacing: -0.02em;
      text-align: left;
      font-family: "Inter";

      &__container {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 122.83px;
      }
    }
  }
}

.tabs__container {
  display: flex;
  align-items: center;
  gap: 8px;

  .tab {
    outline: none;
    cursor: pointer;

    padding: 8px 12px;

    font-size: 14px;
    font-weight: 500;
    color: $colorTextPrimary;

    border-radius: 100px;
    border: 1px solid $colorStrokePrimary;
    background-color: transparent;

    &__active {
      @extend .tab;
      color: $colorTextHighlight;
      border-color: $colorStrokeHighlightSecondary;
    }
  }
}
