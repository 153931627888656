.tabButtonList {
    display: flex;
    gap: 8px;
}

.tabButton {
    padding: 8px 12px;
    border-radius: 100px;
    outline: none;
    border: 1px solid #D9D9D9;
    color: #000;
    background-color: transparent;

    &.active {
        border: 1px solid #6D37F6;
        color: #6D37F6;
    }
}