@import "../../assets/styles/common.scss";

.logoContainer {
  width: 166px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.logo1 {
  width: 100%;
  height: 100%;
  display: flex;
  background-image: url("../../assets/icons/svg/logo.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.logo1_10 {
  width: 100%;
  height: 100%;
  display: flex;
  background-image: url("../../assets/icons/svg/logo.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: $colorNeutral0;
}

.logo2 {
  width: 100%;
  height: 100%;
  display: flex;
  background-image: url("../../assets/icons/svg/logo2.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
