@import "assets/styles/common.scss";

.table {
  width: fit-content;
  border-collapse: collapse;
  table-layout: fixed;

  position: relative;

  font-family: "Inter";

  border: 1px solid #e3e5e8;

  .th {
    vertical-align: middle;
    text-align: center;
  }

  .td {
    border: 1px solid #e3e5e8;
    border-width: 0 0 1px;
  }

  .th {
    border: 1px solid #d3d6db;
    border-width: 1px 1px 2px;
  }
}

.thead {
  .th {
    height: 40px;

    padding: 0 5px 0 8px;

    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
  }
  .tr {
    background-color: #f9f9fa;

    font-size: 12px;
    font-weight: 500;
    line-height: 16px;

    border: 2px solid #e3e5e8;
  }
}

.tbody {
  border: 1px solid #e3e5e8;

  .td {
    height: 52px;

    padding: 0 5px 0 8px;

    vertical-align: middle;
    text-align: left;

    position: relative;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
}

.tfoot {
  .tr {
    border: 1px solid #e3e5e8;
  }

  .td {
    height: 52px;

    background-color: white;
    padding: 0 5px 0 8px;

    vertical-align: middle;
    text-align: left;

    position: relative;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }

  .sum-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;

    color: #98a1b2;
  }
}

.th {
  color: #98a1b2;
}

.td {
  color: #212129;
}

.contragent {
  &-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
  &-inn {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: #98a1b2;
  }
}

.invoices-button {
  user-select: none;
  transition: 0.24s ease-out;

  &__active {
    transform: rotateZ(90deg);
  }
}

.clickable {
  cursor: pointer;
}

.date-expired {
  color: #eb5757;
}
