.table {
  width: fit-content;
  border-collapse: separate;
  table-layout: fixed;

  border-collapse: separate;
  border: 1px solid #e3e5e8;

  &-rounding {
    &__top {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      .rounding:first-child {
        .tr:first-of-type {
          .th,
          .td {
            &:first-of-type {
              border-top-left-radius: 10px;
            }
            &:last-of-type {
              border-top-right-radius: 10px;
            }
          }
        }
      }
    }
    &__bottom {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      .rounding:last-child {
        .tr:last-of-type {
          .th,
          .td {
            &:first-of-type {
              border-bottom-left-radius: 10px;
            }
            &:last-of-type {
              border-bottom-right-radius: 10px;
            }
          }
        }
      }
    }
  }

  th,
  td:first-child {
    vertical-align: middle;
    text-align: center;
  }

  td,
  th {
    border-left: solid #e3e5e8 1px;
    border-top: solid #e3e5e8 1px;
  }

  th {
    border-top: none;
  }

  td:first-child,
  th:first-child {
    border-left: none;
  }
}

.thead {
  .th {
    height: 40px;
    text-align: left;
    padding: 0 8px;
  }
  .tr {
    &:first-child {
      background-color: #e8e8e8;

      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
    }
    &:last-child {
      background-color: #f9f9fa;

      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
    }
  }
}

.th,
.td {
  // border: 1px solid #e3e5e8;
}

.th {
  color: #6b6d7e;
}

.td {
  color: #212129;
}

.tbody {
  border: 1px solid #e3e5e8;

  .td {
    height: 44px;

    vertical-align: middle;
    text-align: left;
    padding: 4px 8px;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    color: #212129;

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      color: #212129;
    }
    span {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      text-align: left;
      color: #98a1b2;
    }

    position: relative;
    border-width: 1px 0;
  }

  .num {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    color: #6b6d7e;
    padding: 0;
  }
}

.clickable {
  cursor: pointer;
}
