.form {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > * {
    flex-grow: 1;
  }

  gap: 24px;
}

.employee-status {
  display: flex;
  flex-direction: column;
  gap: 10px;

  P {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.02em;
  }

  margin-bottom: 19px;
}
