/* Common */

label:hover {
  cursor: pointer;
}

.checkboxInput {
  display: none;
}

/* Unchecked */

.uncheckedVariant1 {
  display: flex;
  width: 24px;
  height: 24px;
  background-image: url("../../assets/icons/svg/checkboxOff.svg");
  background-repeat: no-repeat;
}

/* Checked. Change only class after "+" */

.checkboxInput:checked + .checkedVariant1 {
  display: flex;
  width: 24px;
  height: 24px;
  background-image: url("../../assets/icons/svg/checkboxOn.svg");
  background-repeat: no-repeat;
}
