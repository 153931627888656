@import "assets/styles/common.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  padding: 40px 24px;
  gap: 16px;
}

.content {
  width: calc(100vw - 280px - 24px);
  min-height: calc(100vh - 310px);
  overflow: auto;

  padding-right: 24px;
}

.header {
  display: flex;
  justify-content: space-between;

  & > .title-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  & h2 {
    font-size: 32px;
    font-weight: 800;
    line-height: 40px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .tabs {
    margin-top: 12px;

    width: fit-content;
    background-color: #f9f9fa;

    border: 1px solid #e3e5e8;
    border-radius: 14px;
    padding: 4px;

    :global {
      .ant-segmented-item {
        border-radius: 10px;
        box-shadow: none;
        border: none;

        &-selected {
          background-color: $colorHighlightPrimary;
          .ant-segmented-item-label {
            color: black;
          }
        }

        &-label {
          font-family: "Inter";
          font-size: 14px;
          font-weight: 500;

          color: #272729;
        }
      }
      .ant-segmented-thumb-motion-appear-active {
        background-color: $colorHighlightPrimary;
        border-radius: 10px;
      }
    }
  }

  & > .control-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
  }
}

.buttons-container {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
}

.warning-message {
  margin-top: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 6px 8px 6px 16px;

  border: 1px solid #eb5757;
  border-radius: 8px;

  font-family: "Inter";
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  .warning-icon {
    height: 24px;
    width: 24px;
  }
}
