.status-container {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  gap: 4px;

  width: 100%;
}

.status {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  flex: 1 1;
}

.status-info-icon {
  display: grid;
  place-content: center;

  flex-shrink: 0;
}
