@import "assets/styles/common.scss";

.loans {
  &-wrapper {
    height: fit-content;

    padding: 16px 20px;

    font-family: "Inter";

    * {
      letter-spacing: -0.02em;
    }

    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &-title {
    font-size: 24px;
    font-weight: 800;
    line-height: 32px;
    text-align: left;
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 16px;

    padding-right: 24px;

    & > div {
      &:nth-of-type(1) {
        align-self: flex-end;
      }
      &:nth-last-of-type(1) {
        align-self: flex-start;
      }
    }
  }
}

.tabs__container {
  display: flex;
  align-items: center;
  gap: 8px;

  .tab {
    outline: none;
    cursor: pointer;

    padding: 8px 12px;

    font-size: 14px;
    font-weight: 500;
    color: $colorTextPrimary;

    border-radius: 100px;
    border: 1px solid $colorStrokePrimary;
    background-color: transparent;

    &__active {
      @extend .tab;
      color: $colorTextHighlight;
      border-color: $colorStrokeHighlightSecondary;
    }
  }
}
