@import "assets/styles/common.scss";

.spot {
  &__wrapper {
    max-height: calc(100vh - 56px);
    height: 100%;

    padding: 16px 20px;

    font-family: "Inter";

    * {
      letter-spacing: -0.02em;
    }

    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__title {
    font-size: 32px;
    font-weight: 800;
    line-height: 32px;
    text-align: left;
  }

  &__subtitle {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
