@import "../../assets/styles/common.scss";

.fillStepmaker {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
}
