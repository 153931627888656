/* Common */

.toogleLabel:hover {
  cursor: pointer;
}

.toogleInput {
  display: none;
}

/* Unchecked */

.toogleBack1 {
  position: absolute;
  display: flex;
  width: 48px;
  height: 24px;
  border-radius: 16px;
  border: 1.5px solid #e5e7eb;
  transition: 0.4s;
}

.toogleSwitcher1 {
  position: absolute;
  top: 1.5px;
  left: 2px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  background-color: #d2d6db;
  transition: 0.4s;
}

/* Checked */

.toogleInput:checked + .toogleBack1 {
  border: 1.5px solid #171d27;
  transition: 0.4s;
}

.toogleInput:checked + .toogleBack1 > .toogleSwitcher1 {
  position: absolute;
  top: 1.5px;
  left: 25px;
  display: flex;
  width: 18px;
  height: 18px;
  background-color: #171d27;
  transition: 0.4s;
}
