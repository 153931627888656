.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 4px;
}

.cell {
    border-bottom: none;
}

.mainCell {
    border: 1px solid rgba(215, 215, 215, 1);
    border-radius: 4px;
}

.headerCell {
    background-color: rgba(247, 247, 247, 1);
    padding: 19px 12px;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
}

.header-receipts_count,
.header-average_receipt {
    background-color: rgba(220, 253, 84, 1);
}

.receipts_count,
.average_receipt {
    border: 1px solid rgba(202, 247, 17, 1);

    &:hover {
        border: 1px solid rgba(151, 71, 255, 1);
        transition: 0.2s all;
    }
}

.tableHeader {
    // TODO: От стики ломаются бордеры в .cell
    position: sticky;
    top: 0;
}

.dateCell {
    display: flex;
    gap: 12px;
}

.dateCellDate {
    font-weight: 700;
}

.dateCellWeekday {
    color: rgba(153, 153, 153, 1);
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
}

.bodyCell {
    padding: 18px 12px;
    text-align: center;
}

.tableFooter {
    margin-top: 16px;
    position: sticky;
    bottom: 0;
    background-color: white;
}

.tableLayoutBoxShadow {
    box-shadow: 2px 0px 20px 0px rgba(0, 0, 0, 0.1);
}

.footerCell {
    padding: 12px 6px;
    font-weight: 700;
    text-align: center;
    border-bottom: none;
}