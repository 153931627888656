@import "../styles.scss";

.onboardingPageOne__container {
  height: calc(100vh - 338px);
}

.spotWorkingHours__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  h2 {
    @include subtitleSemiBold;
    color: $colorNeutral100;
  }

  .buttonCommon {
    justify-self: flex-end;
  }
}

.spotWorkingHours__header {
  margin-bottom: 20px;
}

.spotWorkingHours__choose {
  max-width: 462px;
  width: 100%;
  margin-bottom: 32px;
  padding: 3px;
  display: flex;
  border-radius: 12px;
  background-color: $colorNeutral20;
}

.timePick__container {
  max-width: 470px;
  width: 100%;
  height: 106px;
  margin-bottom: 16px;
  padding: 12px 16px 16px;
  border-radius: 24px;
  background-color: $colorNeutral20;
}

.timePick__container:last-of-type {
  margin-bottom: 30px;
}

.timePick__header {
  margin-bottom: 12px;
  text-align: center;
  @include body2Medium;
  color: $colorNeutral50;
}

.timePick__inputs {
  display: flex;
  justify-content: space-between;
}
