@import "assets/styles/common.scss";

.wrapper {
  margin-top: -8px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  font-family: "Inter";
}

.status {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.02em;
  }
}
