@import "assets/styles/colors";

.payment-types {
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > label {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  .subtitle {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: left;

    color: #cccccc;
  }
}

.checkbox {
  &-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &-label {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: left;
  }
}

.tooltip-info {
  max-width: 302px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}
