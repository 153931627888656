
.documentHeader {
    display: flex;
    width: 100%;
    background-color: #F9F9FA;
    padding: 16px;
    border-radius: 20px;
}

.documentHeaderList {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    gap: 16px;
    grid-auto-flow: column;
    grid-auto-columns: minmax(100px, auto);
    flex: 1 1 70%;
}

.buttonsContainer {
    flex: 1 1 30%;
}

.columnItem {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 2px;
}

.buttonsContainer {
    display: flex;
}

.columnItemTitle {
    color: #A6AEBB;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

.columnItemValue {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}