.table {
  width: 100%;
  border-collapse: collapse;
  color: #98a1b2;
}

.th, .td {
  padding: 8px;
}

.bodyRow {
  height: 44px;
}

.bodyColumn {
  border: 1px solid #ddd;
  border-top: none;
  text-align: left;
  vertical-align: middle;
}

.rowNumberText {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subkontoTable {
  width: 100%;
  border-collapse: collapse;
}

.subkontoRow {
  border: 1px solid #ddd;
  border-bottom: none;
}

.subkontoCell {
  height: 44px;
  vertical-align: middle;
  text-align: left;
  padding-left: 8px;
}

.halfWidth {
  width: 50%;
}

.tableItem {
  width: 100%;
}

.numberColumn { width: 3% }
.nameColumn { width: 31.5%; max-width: 320px; }
.countColumn { width: 10%; max-width: 162px; }
.sumColumn { width: 10%; max-width: 162px; }

.subkontoCellFirst {
  max-width: 320px;
  width: 43.2%;
  border: 1px solid #ddd;
  border-left: none;
  border-top: none;

  &:last-child {
    border-bottom: none;
  }
}
.subkontoCellSecond {
  max-width: 320px;
  width: 50%;
  border: 1px solid #ddd;
  border-right: none;
  border-top: none;
}

.nameSelect {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}

.countInput {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding-left: 8px;
  background-color: transparent;
}

.ant-select-selector {
  height: 100% !important;
  width: 100% !important;
  outline: none !important;
  border: none !important;
  display: flex;
  align-items: center;
}

.no-editable-cell {
  padding-left: 12px;
}

.disabledCell {
  background-color: #E4E5E9;
}