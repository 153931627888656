.pageWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
}

.pageTableFooterColumn {
    padding: 12px;
}

.pageHeaderText {
    color: rgba(33, 33, 41, 1);
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
}

.infoContainer {
    border-radius: 8px;
    margin-left: auto;
    border: 1px solid rgba(109, 55, 246, 1);
    color: rgba(109, 55, 246, 1);
    background-color: rgba(109, 55, 246, 0.12);
    padding: 5px 25px;
    display: flex;
    gap: 16px;
}

.infoContainerText {
    font-size: 15px;
    font-weight: 500;
    line-height: 19px;
}

.pageTableFooterColumn {
    &:first-child {
        border-left: 1px solid rgb(227, 230, 235);
    }

    &:last-child {
        border-right: 1px solid rgb(227, 230, 235);
    }
}

.textCell {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: rgba(33, 33, 41, 1);
}

.redQuantity {
    color: rgba(255, 0, 0, 1);
}

.filterSelect {
    min-width: 200px;
}

.pageHeader {
    display: flex;
    flex-direction: column;
}

.pageHeaderTitle {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.updateButtonLoadingTitle {
    display: flex;
    align-items: center;
    gap: 8px;
    color: rgba(0, 0, 0, 0.2);
}

.succeedContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 20px;
    color: rgba(109, 55, 246, 1);
}

.successIcon {
    width: 16px;
    color: rgba(109, 55, 246, 1);
}
