
.modalContainer {
    font-family: "Inter", sans-serif;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 40px;
}

.modalWrapper {
    max-width: 400px;
}

.modalTitle {
    font-size: 24px;
    line-height: 24px;
    font-weight: 800;
}

.modalDescription {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
}

.buttons {
    display: flex;
    width: 100%;
    gap: 12px;
}

.button {
    display: flex;
    flex: 1 1 50%;
}