@import "../styles.scss";

.revenueInputField__container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.revenueInputField {
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 28px 4px 12px;
  gap: 16px;
  background: $colorNeutral10;
  border: 1px solid $colorNeutral30;
  border-radius: 12px;
  width: 100%;
  height: 32px;
  color: $colorInactive;
  @include body2Medium;
}

.revenueInputField::placeholder {
  color: $colorNeutral50;
}

.revenueInputField:focus {
  outline: none;
  color: $colorNeutral50;
}

.revenueInputField_active {
  background: $colorNeutral0;
  color: $colorNeutral100;
  transition: 200ms ease-in-out;

  &::placeholder {
    color: $colorNeutral100;
  }

  &:focus {
    outline: none;
    color: $colorNeutral100;
  }

  &:hover {
    border: 1px solid $colorNeutral50;
  }
}

.revenueInputField__mark {
  position: absolute;
  right: 12px;
  top: 4px;
  @include body2Medium;
  color: $colorNeutral50;
}
.revenueInputField__mark_active {
  color: $colorNeutral100;
}

.revenueInputField__value {
  width: 220px;
  height: 24px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}
