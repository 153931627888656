@import "../../../assets/styles/common.scss";

.authSkeleton__container {
  max-width: 400px;
  width: 100%;
  margin: 15px auto 0px;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  background-color: $colorNeutral0;
  box-shadow:
    0px 2px 8px rgba(0, 0, 0, 0.03),
    0px 4px 20px rgba(0, 0, 0, 0.08);
}

.authSkeleton__logo-container {
  margin: 0px auto 0px;
}

.authSkeleton__content-container {
  padding: 32px;
  display: flex;
  flex-direction: column;
}

.authSkeleton__content-container__header {
  margin-bottom: 8px;
}

.authSkeleton__content-container__discription,
.authSkeleton__content-inputs {
  margin-bottom: 32px;
}
