@import "assets/styles/common.scss";

.date-selector {
  display: flex;
  align-items: center;
}

.month-selector {
  display: flex;
  gap: 8px;
  align-items: center;

  p {
    min-width: 130px;

    font-size: 16px;
    font-weight: 800;
    color: $colorTextPrimary;

    text-transform: capitalize;
    text-align: center;
  }

  .button {
    &-next {
    }

    &-prev {
      transform: rotateY(-180deg);
    }

    &-next,
    &-prev {
      outline: none;
      cursor: pointer;

      border: none;

      width: 32px;
      height: 32px;

      mask: url("../../assets/icons/svg/schedule/arrow_right_icon.svg")
        no-repeat center;
      mask-size: auto;

      background-color: black;

      &:disabled {
        background-color: #d9d9d9;
      }
    }
  }
}
