@import "../styles.scss";

.operation-input {
  padding: 16px 20px;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    width: 100%;
    padding: 0 0 20px 0;

    p {
      color: $colorNeutral90;
      @include title3SemiBold;
    }
  }

  &__table {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 16px 20px;
    width: 100%;

    &__header {
      display: flex;
      justify-content: space-between;

      padding-bottom: 0;
      width: 100%;

      .ant-dropdown-trigger {
        svg {
          transition: 200ms ease-in-out;
        }

        &.ant-dropdown-open svg {
          transform: rotate(180deg);
        }
      }
    }

    &__inner {
      display: flex;
      flex-direction: column;

      width: 100%;

      border-radius: 10px;
      border: 1px solid $colorNeutral20;

      &-header {
        display: flex;

        width: 100%;

        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        &__element {
          display: flex;
          align-items: center;
          align-self: stretch;
          gap: 4px;
          cursor: pointer;

          min-width: 40px;
          height: 40px;
          padding: 12px 8px 12px 12px;

          @include captionSemiBold;
          color: $colorNeutral60;
          background: $colorNeutral5;
          border-bottom: 2px solid $colorNeutral20;

          svg {
            &.asc {
              path:last-child {
                fill: $colorNeutral90;
              }
            }
            &.desc {
              path:first-child {
                fill: $colorNeutral90;
              }
            }
          }

          &.sort {
            user-select: none;
            &:hover {
              color: $colorNeutral40;
              background: $colorNeutral20;
              border-bottom: 2px solid $colorNeutral30;
            }

            &:active {
              color: $colorNeutral50;
              background: $colorNeutral30;
              border-bottom: 2px solid $colorNeutral40;
            }
          }

          &:first-child {
            border-top-left-radius: 10px;
          }

          &:nth-child(2) {
            min-width: 130px;
          }
          &:nth-child(3) {
            min-width: 130px;
          }
          &:nth-child(4) {
            min-width: 170px;
          }
          &:nth-child(5) {
            flex: 1;
          }
          &:nth-child(6) {
            flex: 2;
            border-top-right-radius: 10px;
          }
        }
      }

      &-body {
        display: flex;
        flex-direction: column;

        width: 100%;

        &__element:not(:last-child) {
          border-bottom: 1px solid $colorNeutral20;
        }

        &__element {
          display: flex;
          cursor: pointer;
          &__part {
            display: flex;

            align-items: center;
            gap: 4px;

            min-width: 36px;
            height: 40px;
            padding: 12px 8px 12px 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @include body2Regular;
            color: $colorNeutral90;

            &:nth-child(2) {
              min-width: 130px;
            }

            &:nth-child(3) {
              min-width: 130px;
            }

            &:nth-child(4) {
              min-width: 170px;
            }

            &:nth-child(5) {
              flex: 1;
            }

            &:nth-child(6) {
              flex: 2;
            }
          }
        }
      }

      &-footer {
      }
    }
  }
}
