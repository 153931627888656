.select-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.ant-select-selection-search-input {
  min-height: 100%;
}

.select-option-main-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 60%;
}

.select-option-type-label {
  font-size: 12px;
  color: #a6aebb;
}

.ant-select-selection-item {
  height: 100%;
  padding-top: 5px;
}

.select-tooltip-container {
  display: flex;
  flex-direction: column;
}

.select-tooltip-item {
  font-size: 14px;
}

.select-tooltip-item-label {
  font-weight: 800;
}
