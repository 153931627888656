@import "./fonts.scss";
@import "./colors.scss";
@import "./classes.scss";

@mixin title3SemiBold {
  font-family: $interFont;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}
@mixin title3Bold {
  font-family: $interFont;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

@mixin header5SemiBold {
  font-family: $interFont;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}

// SUBTITLE
@mixin subtitleSemiBold {
  font-family: $interFont;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

@mixin subtitleRegular {
  font-family: $interFont;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
}

// BODY 1
@mixin body1Regular {
  font-family: $interFont;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
@mixin body1Medium {
  font-family: $interFont;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
@mixin body1SemiBold {
  font-family: $interFont;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

// BODY 2
@mixin body2Regular {
  font-family: $interFont;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
@mixin body2Medium {
  font-family: $interFont;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
@mixin body2SemiBold {
  font-family: $interFont;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

// CAPTION
@mixin captionRegular {
  font-family: $interFont;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
@mixin captionMedium {
  font-family: $interFont;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
@mixin captionSemiBold {
  font-family: $interFont;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

//TOOLTIP
@mixin tooltipRegular {
  font-family: $interFont;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

// TAG
@mixin tagRegular {
  font-family: $interFont;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
}
@mixin tagMedium {
  font-family: $interFont;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
}
