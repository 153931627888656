.container {
  position: relative;
}

.activator {
  position: absolute;
  bottom: 35%;
  right: 12px;
  z-index: 1;
}

.content {
  p {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }
}
