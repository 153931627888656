@import "../styles.scss";

.finance {
  &__header {
    @include title3SemiBold;
    color: $colorPrimaryDark;

    padding: 16px 20px;
  }

  &__body {
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;

    &__status {
      display: flex;
      align-items: center;
      gap: 16px;

      &-element {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        transition: 200ms ease-in-out;

        &__number {
          display: flex;
          padding: 8px;
          align-items: center;
          justify-content: center;
          gap: 8px;
          width: 36px;
          height: 36px;
          border-radius: 22px;
          background: $colorNeutral10;
          @include body2Medium;
          color: $colorInactive;
          transition: 200ms ease-in-out;

          &.active {
            color: $colorPrimaryLight;
            background: $colorPrimaryDefault;
          }
        }

        &__title {
          @include body2Medium;
          color: $colorInactive;
          transition: 200ms ease-in-out;

          &.active {
            color: $colorPrimaryDefault;
          }
        }
      }

      &-bar {
        width: 95.5px;
        height: 2px;
        border-radius: 1px;
        background: $colorNeutral20;
        transition: 200ms ease-in-out;

        &.active {
          background: $colorPrimaryDefault;
        }
      }
    }

    &__action {
      display: flex;
      width: 572px;
      padding: 24px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;
      border-radius: 24px;
      border: 1px solid $colorNeutral20;
      background: $colorNeutral0;

      .title {
        color: $colorPrimaryDark;
        @include subtitleSemiBold;
      }

      .subtitle {
        color: $colorSecondary;
        @include body2Regular;
      }

      .buttons {
        display: flex;
        gap: 8px;

        button {
          flex-grow: 1;

          &.fillButton {
            width: 195px;
          }
        }
      }

      .loader {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        align-self: stretch;

        .lds-ring {
          display: inline-block;
          position: relative;
          width: 52px;
          height: 52px;

          .loader-background {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 36px;
            height: 36px;
            margin: 8px;
            border: 3px solid $colorNeutral10;
            border-radius: 50%;
            animation: none;
            z-index: -1;
          }
        }

        .lds-ring div {
          box-sizing: border-box;
          display: block;
          position: absolute;
          width: 36px;
          height: 36px;
          margin: 8px;
          border: 3px solid $colorPrimaryDefault;
          border-radius: 50%;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          border-color: $colorPrimaryDefault transparent transparent transparent;
        }

        .lds-ring div:nth-child(1) {
          animation-delay: -0.45s;
        }

        .lds-ring div:nth-child(2) {
          animation-delay: -0.3s;
        }

        .lds-ring div:nth-child(3) {
          animation-delay: -0.15s;
        }

        @keyframes lds-ring {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        &__title {
          @include body2Regular;
          color: $colorPrimaryDark;
        }
      }
    }
  }
}
