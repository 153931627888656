@import "assets/styles/common.scss";

.shift-create__form {
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 5px;
}

.templates__list {
  display: flex;
  flex-direction: column;
  align-items: start;

  margin: 0 0 24px;

  gap: 16px;

  width: max-content;
  height: 490px;
  overflow: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0;
    display: none; // Safari and Chrome
    height: 0;
    -webkit-appearance: none;
  }

  .shift-create__template {
    cursor: pointer;

    transition: all 0.25s;

    &:hover {
      border-color: #888888;
    }

    &-selected {
      border-color: $colorStrokeHighlightSecondary;
      &:hover {
        border-color: $colorStrokeHighlightSecondary;
      }
    }
  }
}

.shift-create__time-container {
  display: flex;
  align-items: flex-end;
  gap: 24px;

  .buttons {
    margin-left: 8px;
    margin-bottom: 19px;
  }
}

.buttons-group {
  align-self: end;

  button {
    width: 110px;
  }
}

.bar {
  display: flex;
  gap: 20px;
  align-items: center;

  .worker-fio {
    text-transform: capitalize;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: -0.02em;
  }
}

.shift-edit__form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: start;

  .shift-edit__template-container {
    display: flex;
    flex-direction: column;
    align-self: start;
    gap: 8px;

    .select-template__button {
      outline: none;
      cursor: pointer;

      border: none;
      background-color: transparent;

      width: max-content;

      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.02em;
      color: $colorTextHighlight;
    }
  }

  .shift-edit__buttons {
    align-self: stretch;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .shift-edit__delete {
      outline: none;
      cursor: pointer;

      border: none;
      background-color: transparent;

      display: flex;
      align-items: center;
      gap: 8px;

      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      color: $colorErrorNew;
    }
  }
}
