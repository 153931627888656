.pageWrapper {
    display: flex;
    flex-direction: column;
    font-family: "Inter", sans-serif;
    padding: 40px 24px;
    gap: 16px;
    height: 100%;
}

.header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: sticky;
    left: 8px;
    gap: 8px;
}

.pageContainer {
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.headerActions {
    display: flex;
    gap: 8px;
}

.headerText {
    font-size: 32px;
    font-weight: 800;
    line-height: 40px;
}

.dateCell {
    display: flex;
    gap: 8px;
}

.monthContainer {
    display: flex;
    justify-content: space-between;
}

.subtitle {
    max-width: 665px;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
}

.infoContainer {
    border-radius: 8px;
    margin-left: auto;
    border: 1px solid rgba(109, 55, 246, 1);
    color: rgba(109, 55, 246, 1);
    background-color: rgba(109, 55, 246, 0.12);
    padding: 5px 25px;
    display: flex;
    align-items: center;
    gap: 16px;
}

.infoContainerText {
    font-size: 12px;
    font-weight: 500;
    line-height: 19px;
    max-width: 500px;
}

.iconWrapper {
    width: 25px;
    height: 25px;

    & > svg {
        width: 100%;
        height: 100%;
    }
}

.periodWrapper {
    display: flex;
    align-items: center;
    gap: 12px;
}

.main {
    height: 100%;
}