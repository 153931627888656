.table {
  width: 100%;
  border-collapse: collapse;
}

.th, .td {
  border: 1px solid #ddd;
  padding: 12px 8px;
  font-weight: 600;
}

.bodyRow {
  border: 1px solid #ddd;
}

.bodyColumn {
  border: 1px solid #ddd;
}

.subkontoTable {
  width: 100%;
  border-collapse: collapse;
}

.subkontoRow {
  border: 1px solid #ddd;
}

.subkontoCell {
  border: 1px solid #ddd;
  padding: 8px;
}

.halfWidth {
  width: 50%;
}

.tableItem {
  width: 100%;
}

.numberColumn { width: 3% }
.nameColumn { width: 31.5% }
.countColumn { width: 10% }
.sumColumn { width: 10% }

.subkontoCellFirst { width: 43.2% }
.subkontoCellSecond { width: 50% }

.nameSelect {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}

.countInput {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}

.thead {
  color: #98a1b2;
  line-height: 16px;
  font-size: 14px;
  text-align: left;
  font-family: "Inter", sans-serif;
  background-color: #f9f9fa;
  //border-radius: 12px;
}