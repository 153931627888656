@import "../../assets/styles/common.scss";

.period-calendar {
  z-index: 20;
  &--hidden {
    display: none !important;
  }
  @include body2Regular;
  width: 250px;
  background: white;
  border: 1px solid $colorNeutral30;
  border-radius: 16px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  overflow: hidden;
  box-shadow:
    0 4px 20px 0 rgba(0, 0, 0, 0.08),
    0 2px 8px 0 rgba(0, 0, 0, 0.03);
  * {
    text-decoration: none;
    border: none;
    outline: none;
  }
  *,
  *:before,
  *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  button {
    margin: 0;
    border: 0;
    outline: none;
    &:enabled:hover {
      cursor: pointer;
    }
  }
  .react-calendar {
    &__tile {
      @include body2Regular;

      padding: 10px 6.6667px;
      background: none;
      text-align: center;
      line-height: 16px;
      &:hover {
        background-color: $colorNeutral20;
        color: $colorNeutral90;
        border-radius: 8px;
      }

      &--range {
        color: $colorNeutral0;
        background: #44a9c2;
        border-radius: 0;
        &:hover {
          background-color: $colorNeutral20;
          color: $colorNeutral90;
          border-radius: 0;
        }
        &End {
          border-radius: 0 8px 8px 0 !important;
          &.react-calendar__tile--hasActive {
            background-color: $colorAccent;
            &:hover {
              background-color: $colorNeutral20;
              color: $colorNeutral90;
            }
          }
        }
        &Start {
          border-radius: 8px 0 0 8px !important;
          &.react-calendar__tile--hasActive {
            background-color: $colorAccent;
            &:hover {
              background-color: $colorNeutral20;
              color: $colorNeutral90;
            }
          }
        }
        &BothEnds {
          border-radius: 8px !important;
        }
        &.react-calendar__month-view__days__day--neighboringMonth {
          color: $colorNeutral30;
          &:hover {
            color: $colorNeutral90;
          }
        }
      }
      &--hover {
        background-color: #f1f2f4;
        color: $colorNeutral90 !important;
        &End {
          border-radius: 0 8px 8px 0 !important;
        }
        &Start {
          border-radius: 8px 0 0 8px !important;
        }
        &BothEnds {
          border-radius: 8px !important;
        }
      }
      &--now {
        @include body2SemiBold;
      }
    }

    &__navigation {
      display: flex;
      height: 44px;
      margin-bottom: 1em;
      button {
        min-width: 44px;
        background: none;
        border-radius: 8px;
        &:disabled {
          background-color: #f0f0f0;
        }
        &:enabled:hover {
          background-color: #e6e6e6;
        }
      }
      &__arrow:hover {
        background-color: $colorAccent35;
      }
    }

    &__month-view {
      &__weekdays {
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.75em;
        &__weekday {
          padding: 0.5em;
          text-decoration: none;
          color: $colorNeutral50;
        }
      }
      &__weekNumbers .react-calendar__tile {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.75em;
        font-weight: bold;
      }
      &__days__day--neighboringMonth {
        color: $colorNeutral60;
      }
    }
  }
}
