.tableSkeleton {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.th {
  padding: 19px 24px;
  padding-right: 44px;
}

.th,
.td {
  border-bottom: 1px solid #e3e6eb;
}

.tableWrapper {
  position: relative;
  overflow-x: auto;
  width: 100%;
  max-width: 100%;
}

.table {
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 100%;
}

.clickable {
  cursor: pointer;
}

.headerRow {
  background-color: #f9f9fa;
  border: 1px solid #e3e5e8;
}

.headerCell {
  font-weight: 600;
  font-size: 12px;
  color: #98a1b2;
  padding: 12px 8px;
  border-right: 1px solid #d3d6db;
}

.bodyCell {
  position: relative;
  margin: 0;
  border-bottom: 1px solid #e3e6eb;
  padding: 8px 12px;

  &:first-child {
    border-left: 1px solid #e3e6eb;
  }

  &:last-child {
    border-right: 1px solid #e3e6eb;
  }

  &.sort {
    padding-right: 44px;
  }
}

.expandRow {
  border: 1px solid #e3e5e8;
}

.indexCell {
  border-right: none;
  width: 3%;
}

.deleteHeaderCell {
  border-left: none;
}

.deleteCell {
  border-right: 1px solid #e3e6eb;
  width: 3%;
}

.deleteButton {
  svg {
    color: #e35656;
  }
}

.indexText {
  font-size: 14px;
  color: rgba(152, 161, 178, 1);
  font-weight: 400;
}

.cellText {
  font-size: 14px;
}

.textCell {
  text-align: center;
}
