.tableWrapper {
    position: relative;
    overflow-x: auto;
    width: 100%;
    max-width: 100%;
}

.table {
    border-collapse: collapse;
    border-spacing: 0;
    min-width: 100%;
}

.clickable {
    cursor: pointer;
}

.headerRow {
    background-color: #f9f9fa;
    border: 1px solid #e3e5e8;
}

.headerCell {
    font-weight: 600;
    font-size: 12px;
    color: #98A1B2;
    padding: 12px 8px;
    border-right: 1px solid #d3d6db;
}

.bodyCell {
    position: relative;
    margin: 0;
    border-bottom: 1px solid #e3e6eb;

    &:first-child {
        border-left: 1px solid #e3e6eb;
    }

    &:last-child {
        border-right: 1px solid #e3e6eb;
    }

    &.sort {
        padding-right: 44px;
    }
}

.expandRow {
    border: 1px solid #e3e5e8;
}

.indexCell {
    border-right: none;
    width: 3%;
}

.deleteCell {
    border-right: 1px solid #e3e6eb;
    width: 3%;
}

.deleteButton {
    margin: 0 auto;

    svg {
        color: #e35656;
    }
}

.indexText {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: rgba(152, 161, 178, 1);
    font-weight: 400;
}

.cellText {
    font-size: 14px;
}

.arrowIcon {
    color: rgba(152, 161, 178, 1);
    width: 20px;
    height: 20px;
    transition: 0.2s ease-in-out;

    &.rotate {
        transform: rotate(90deg);
    }
}

.expandButton {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: center;
}

.tableFooter {
    border: 1px solid rgba(227, 229, 232, 1);
}