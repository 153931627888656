@import "../../assets/styles/common";

.notifications {
  position: absolute !important;
  z-index: 50;
  top: 45px;
  right: 50px;
  box-shadow:
    0px 4px 20px 0px rgba(0, 0, 0, 0.08),
    0px 2px 8px 0px rgba(0, 0, 0, 0.03);

  border-radius: 16px;
  overflow: hidden;
  border: 1px solid $colorNeutral30;
  &__noitems {
    height: 100%;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    gap: 8px;
    p:first-of-type {
      @include body1SemiBold;
      max-width: 300px;
    }
    p:nth-child(2) {
      @include body2Regular;
      max-width: 300px;
      color: #6b6d7e;
      text-align: center;
    }
  }
  &__wrapper {
    width: 500px;
    border-radius: 16px;
    background-color: #fff;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    border-bottom: 1px solid $colorNeutral30;
  }
  &__title {
    @include body2SemiBold;
    color: $colorPrimaryDark;
  }
  &__read-all {
    color: $colorPrimaryDefault;
    @include captionSemiBold;
    cursor: pointer;
    &--disabled {
      color: #a4a5b0;
      cursor: auto;
    }
  }
  &__content {
    padding: 16px 8px;
    overflow-y: auto;
    height: 540px;
    //margin-bottom: 50px;
  }
  &__list {
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;
  }
  &__group {
    display: flex;
    flex-flow: column nowrap;
    &__title {
      @include body2SemiBold;
      margin-bottom: 8px;
      color: $colorInactive;
      padding: 0 12px;
    }
  }

  // SINGLE NOTIFICATION
  &-item {
    padding: 12px;
    &:hover {
      background-color: $colorNeutral5;
    }
    border-radius: 8px;
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 4px;
      cursor: pointer;
      &__left {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
    &__title {
      cursor: pointer;
      margin-bottom: 8px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 8px;
      &__text {
        flex: 1 0;
        @include body2Regular;
        b {
          @include body2Medium;
        }
      }
    }

    &__tag {
      padding: 2px 8px;
      @include captionMedium;
      color: $colorNeutral0;
      border-radius: 4px;
      &--blue {
        background-color: #57b5c2;
      }
      &--red {
        background-color: #ee4c3e;
      }
      &--green {
        background-color: #75b224;
      }
      &--grey {
        background-color: #a0a0aa;
      }
    }
    &__time-date {
      @include captionMedium;
      color: $colorInactive;
    }
    &__dot {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      .notifications-dot {
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background-color: $colorNeutral30;
        &--unread {
          background-color: $colorInfoFill;
        }
      }
    }
    &__arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
    }
  }
  &-details {
    border-left: 2px solid $colorNeutral30;
    padding-left: 16px;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    &__title {
      @include body2SemiBold;
      color: $colorPrimaryDark;
      margin-bottom: 4px;
    }
    &__text {
      margin-bottom: 8px;
      @include body2Regular;
      b {
        @include body2Medium;
      }
    }
    &__button {
      align-self: flex-start;
      border: 1px solid $colorNeutral20;
      border-radius: 10px;
      padding: 6px 12px;
      @include body2Medium;
      color: $colorPrimaryDark;
      background-color: #ffffff00;
    }
  }
}
