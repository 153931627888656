@import "assets/styles/common.scss";

.sort-button {
  display: flex;
  justify-content: space-between;
  gap: 13px;
  align-items: center;

  outline: none;
  cursor: pointer;

  border: none;
  background-color: transparent;
  color: inherit;
}
