@import "../styles.scss";

.main-calendar {
  border: 1px solid $colorNeutral30;
  border-radius: 8px;

  .revenue-calendar__input-wrapper {
    position: relative;

    .basicInput {
      padding-right: 13px;
    }

    &::after {
      content: "₽";
      display: block;
      position: absolute;
      right: 0;
      bottom: 2px;
      @include captionSemiBold;
      color: $colorPrimaryDark;
    }
  }

  .react-calendar__month-view {
    border-radius: 16px;
    //   border: 1px solid $colorNeutral30;
  }

  .react-calendar__month-view div {
    width: 100%;
  }

  .react-calendar__month-view__weekdays {
    border-bottom: 1px solid $colorNeutral30;
  }

  .react-calendar__month-view__weekdays__weekday {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px;
  }

  .react-calendar__month-view__weekdays__weekday abbr {
    @include captionSemiBold;
    color: $colorNeutral50;
    text-transform: none;
  }

  .react-calendar__month-view__days__day {
    @include body2SemiBold;
    color: $colorInfoFill;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    &.main__tile {
      background-color: $colorNeutral5;
    }

    abbr {
      @include body2SemiBold;
      color: $colorInactive;
    }
  }

  .react-calendar__month-view__weekdays__weekday:not(:last-child) {
    border-right: 1px solid $colorNeutral30;
  }

  .react-calendar__navigation {
    display: none;
  }

  .main__tile {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 4px 8px;
    gap: 8px;
    background: $colorNeutral0;
    border: none;
    border-right: 1px solid $colorNeutral30;
    border-bottom: 1px solid $colorNeutral30;
    pointer-events: none;
    position: relative;

    &.react-calendar__tile:hover {
      background-color: $colorNeutral0;
      cursor: initial;
    }
  }

  .main__tile:nth-child(7n) {
    border-right: none;
  }

  .main__tile:nth-last-child(-n + 7) {
    border-bottom: none;
  }

  .main__tile:nth-last-child(-7n + 7) {
    border-bottom-left-radius: 16px;
  }

  .main__tile:last-child {
    border-radius: 16px;
  }

  .revenueCalendar__tile-revenue {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    @include captionRegular;
    color: $colorNeutral50;

    span {
    }

    .disabled {
      input {
        pointer-events: none;
      }
    }

    .basicInput {
      pointer-events: auto;
      border: none;
      border-bottom: 1px solid #d2d6db;
      border-radius: 0px;
      text-align: right;
      @include body2Medium;
      color: $colorPrimaryDark;

      &:hover {
        background: $colorNeutral0;
        cursor: initial;
      }
    }
  }
}
