@import "../../assets/styles/common.scss";

.sidebar__container {
  min-width: 280px;
  min-height: calc(100vh - 56px);
  height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  background-color: $colorNeutral0;

  .buttonCommon {
    border: 1px solid $colorNeutral30;
  }
}

.sidebar__menu {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid $colorNeutral30;

  .sidebar__tour {
    margin-bottom: 26px;
    margin-left: 16px;
    display: inline-flex;
    align-items: center;
    gap: 8px;

    svg {
      cursor: pointer;
    }

    span {
      @include body2Medium;
      color: $colorNeutral50;
    }
  }
}

.sidebar__menu__main,
.sidebar__menu__sub {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sidebar__menu__main__spot-accorion-vision {
  display: none;
}
