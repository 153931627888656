@import "../../src/assets/styles/common.scss";
@import "./styles/index.scss";

// title popup

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.2s;
  visibility: visible;
}
[data-title]:after {
  @include tooltipRegular;
  content: attr(data-title);
  background-color: $colorNeutral100;
  color: $colorPrimaryLight;
  display: flex;
  align-items: center;
  position: absolute;
  padding: 2px 6px;
  gap: 4px;
  bottom: -30px;
  transform: translateY(50%);
  left: 0;
  max-width: 200px;

  opacity: 0;

  z-index: 99999;
  visibility: hidden;
  border-radius: 4px;
}
[data-title] {
  position: relative;
}

.timesheet__table-body-element[data-title]:after {
  bottom: 12px;
  left: 12px;
  transform: translateY(100%);
  max-width: 60px;
  white-space: pre-wrap;
  line-height: 8px;
  padding: 6px 6px;
}

//.reactour__popover {
//  border-radius: 20px;
//  padding: 16px !important;
//  @include body2SemiBold;
//  span {
//    background-color: rgb(66, 66, 66) !important;
//    color: white !important;
//    @include body1Regular;
//    font-size: 18px !important;
//  }
//  .reactour__close-button {
//    top: 10px !important;
//    right: 10px !important;
//    color: grey;
//  }
//}
//.tour-item {
//  &_title {
//    @include title3SemiBold;
//  }
//  &_desc {
//    @include body2Regular;
//  }
//}

.reactour__popover {
  @include body2Regular;
  color: $colorNeutral90;
  background: $colorNeutral0;
  &_title {
    @include title3SemiBold;
  }
  &_desc {
    @include body2Regular;
  }
  span {
    @include tagMedium;
    background: $colorInfoFill;
    padding: 8px;
    box-shadow:
      0px 1px 3px 0px rgba(77, 77, 77, 0.2),
      0px 4px 12px 0px rgba(0, 0, 0, 0.15);
    color: $colorNeutral0;
    display: flex;
    align-items: center;
  }
}
