.form {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.fields-wrapper {
  display: flex;
  flex-direction: column;
}

.sub-header {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: black;
}

.field-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.label {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: black;

  &.disabled {
    color: #cccccc;
  }
}

.sub-label {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: #cccccc;
}
