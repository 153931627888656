@import "assets/styles/common.scss";

.wrapper {
  font-family: "Inter";
  padding: 16px 20px;

  display: flex;
  flex-direction: column;
  gap: 24px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 8px;
  cursor: pointer;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6b6d7e;

  .grey {
    &:hover {
      color: $colorNeutral70;
    }

    &:active {
      color: $colorNeutral80;
    }
  }

  .black {
    color: #272729;
  }
}

.title-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;

  .text {
    display: flex;
    gap: 16px;

    .backlink {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      gap: 8px;
      width: 32px;
      height: 32px;
      background-color: #caf711;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}

.buttons-group {
  display: flex;
  gap: 12px;
  align-items: center;

  button {
    height: 40px;
  }
}

.transaction-delete__button {
  outline: none;
  cursor: pointer;

  border: none;
  background-color: transparent;

  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: $colorErrorNew;
}

.form {
  &-container {
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 24px;
  }
  &-column {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.row {
  display: flex;
  gap: 16px;
  align-items: flex-start;
  width: 100%;

  & > * {
    flex-grow: 1;
  }
}

.document-status {
  display: flex;
  align-items: center;
  gap: 14px;

  padding: 11px 12px 11px 15px;
  border-radius: 8px;

  span {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 19px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  &.approved {
    border: 1px solid #6d37f6;
    background-color: rgba(109, 55, 246, 0.12);

    span {
      color: #6d37f6;
    }
  }
  &.deleted {
    border: 1px solid #eb5757;
    background-color: rgba(235, 87, 87, 0.12);

    span {
      color: #eb5757;
    }
  }
}

:global(.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper) {
  color: inherit !important; // Ваш цвет
}
