@import "../../styles.scss";

.balance__datepicker-wrapper {
  margin-left: 20px;
  margin-bottom: 8px;
}
// Активные классы
.reportTable__balanceItem {
  display: flex;
  width: 100%;
}

.reportTable__balanceItem:not(:last-child) {
  border-bottom: 1px solid #e5e7eb;
}

.reportTable__balanceItem:last-child {
  flex: 1;
  height: 100%;
}

.reportTable__nominations {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  width: 100%;
  flex: 900 900;
  border-right: 1px solid #e5e7eb;
}

.reportTable__nominationsTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 12px;
  text-transform: uppercase;
}

.reportTable__nominationsItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 0px 50px;
}

.reportTable__nominationsItemMain {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0px;
  gap: 12px;
}

.reportTable__nominationsItemUnit {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px 8px 34px;
  gap: 12px;
}

.reportTable__summary {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;
  width: 100%;
  flex: 260 260;
  border-left: 1px solid #e5e7eb;
  background-color: #f5f6f7;
}

.summary__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 12px;
  gap: 8px;
  text-align: right;
  width: 100%;
}

.summary__item_main {
  text-align: right;
  justify-content: space-between;
}

// global

.reports__wrapper {
  // min-width: 1160px;
  width: 100%;
  // height: 100%;
  display: flex;
  flex-direction: column;
}

/* обертка самой таблицы */

.reports__main {
  // min-width: 1160px;
  // width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  isolation: isolate;
  border-top: 1px solid #e5e7eb;
  border-right: 1px solid #e5e7eb;
  flex: none;
  order: 2;
  align-self: stretch;
  flex: 1;
}

/* хеадер таблицы ОСВ */

.reportTable__header {
  display: flex;
  width: 100%;
}

.reportTable__headerOSV {
  display: flex;
  width: 100%;
}

.reportTable__headerOSV_left {
  min-width: 470px;
  flex: 470 470;
}

.reportTable__headerOSV_right {
  min-width: 690px;
  flex: 690 690;
}

.goods__title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 46px 16px 4px;
  gap: 16px;
  height: 68px;
  border-bottom: 1px solid #e5e7eb;
  width: 100%;
  flex: 5 5;
}

.goods__title:first-child {
  flex: 1 1;
}

.column__header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  height: 68px;
  background: #ffffff;
  border-bottom: 1px solid #e5e7eb;
  text-align: center;
  border-left: 1px solid #e5e7eb;
}

.column__title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 12px 4px;
  gap: 24px;
  width: 100%;
  height: 34px;
  border-bottom: 1px solid #e5e7eb;
}

.column__subtitle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  height: 34px;
}

.column__subtitle span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 12px 4px;
  gap: 24px;
  width: 50%;
  height: 34px;
}

.column__subtitle span:first-child {
  border-right: 1px solid #e5e7eb;
}

/* обертка мейна таблицы*/

/* компонент из таблицы ОСВ */

.reportTable__mainItem {
  display: flex;
  width: 100%;
}

.reportTable__mainItem:last-child {
  height: 100%;
}

.reportTable__itemWrapper {
  display: flex;
}

.reportTable__itemWrapper_left {
  min-width: 470px;
  flex: 470 470;
}

.reportTable__itemWrapper_right {
  min-width: 690px;
  flex: 690 690;
}

.codes__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px 16px;
  min-width: 90px;
  height: 38px;
  flex: 1 1;
}

.names__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 12px;
  min-width: 380px;
  height: 38px;
  text-transform: uppercase;
  flex: 5 5;
}

.column__item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 8px 12px;
  gap: 24px;
  width: 100%;
  height: 38px;
  text-align: right;
  border-left: 1px solid #e5e7eb;
  background-color: #f5f6f7;
  height: 100%;
}

/* обертка для всей страницы */

.reports__wrapper {
  // min-width: 1160px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* обертка самой таблицы */

/* хеадер таблицы ОСВ */

.reportTable__header {
  display: flex;
  width: 100%;
}

.reportTable__headerOSV {
  display: flex;
  width: 100%;
}

.reportTable__headerOSV_left {
  min-width: 470px;
  flex: 470 470;
}

.reportTable__headerOSV_right {
  min-width: 690px;
  flex: 690 690;
}

.goods__title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 46px 16px 4px;
  gap: 16px;
  height: 68px;
  border-bottom: 1px solid #e5e7eb;
  width: 100%;
  flex: 5 5;
}

.goods__title:first-child {
  flex: 1 1;
}

.column__header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  height: 68px;
  background: #ffffff;
  border-bottom: 1px solid #e5e7eb;
  text-align: center;
  border-left: 1px solid #e5e7eb;
}

.column__title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 12px 4px;
  gap: 24px;
  width: 100%;
  height: 34px;
  border-bottom: 1px solid #e5e7eb;
}

.column__subtitle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  height: 34px;
}

.column__subtitle span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 12px 4px;
  gap: 24px;
  width: 50%;
  height: 34px;
}

.column__subtitle span:first-child {
  border-right: 1px solid #e5e7eb;
}

/* обертка мейна таблицы*/

/* компонент футера с суммой таблицы ОСВ */

.reportTable__footer {
  display: flex;
  width: 100%;
  height: 46px;
}

.reportTable__footerEmpty {
  flex: 470 470;
}

/* обертка для всей страницы */

.reportTable__header {
  display: flex;
  width: 100%;
}

.reportTable__headerBalance {
  display: flex;
  width: 100%;
}

.reportTable__headerBalance_left {
  flex: 900 900;
}

.reportTable__headerBalance_right {
  flex: 260 260;
}

.nominations__title {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 16px 4px;
  gap: 16px;
  width: 100%;
  height: 34px;
  border-bottom: 1px solid #e5e7eb;
  border-right: 1px solid #e5e7eb;
}

.summary__title {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0px;
  width: 100%;
  height: 34px;
  background: #ffffff;
  border-bottom: 1px solid #e5e7eb;
  text-align: right;
  border-left: 1px solid #e5e7eb;
  padding: 12px 12px 4px;
}

/* обертка таблицы*/

.reportTable__main {
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: 100%;
  flex: 1;
}
