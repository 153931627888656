@import "../../assets/styles/common.scss";

.employeesList {
  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__header {
    margin-bottom: 24px;
    text-align: center;
    @include body1SemiBold;
    color: $colorNeutral100;
  }

  &__roster {
    display: flex;
    flex-direction: column;

    &__headers {
      display: flex;
      margin-bottom: 24px;

      span {
        @include body2Medium;
        color: $colorNeutral50;
      }
    }

    &__empoyees {
      display: flex;
    }
  }
}
