.pageWrapper {
    height: 100%;
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-x: auto;
}

.headerText {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #212129;
}

.pageHeader {
    padding-left: -16px;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 8px;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
}

.headerTitle {
    display: flex;
    justify-content: space-between;
}

.pageMain {
    height: 100%;
}

.pageActions {
    display: flex;
    gap: 6px;
}

.infoContainer {
    border-radius: 8px;
    margin-left: auto;
    border: 1px solid rgba(109, 55, 246, 1);
    color: rgba(109, 55, 246, 1);
    background-color: rgba(109, 55, 246, 0.12);
    padding: 5px 25px;
    display: flex;
    gap: 16px;
}
