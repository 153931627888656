.wrapper {
  padding: 8px 16px;
}

.table {
  width: fit-content;
  border-collapse: collapse;
  table-layout: fixed;

  border: 1px solid #e3e5e8;

  thead {
    tr {
      border: 1px solid #e3e5e8;
      border-width: 1px 1px 2px 1px;
    }
    th {
      height: 40px;

      vertical-align: middle;
      text-align: left;

      background-color: rgba(186, 221, 254, 0.24);

      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      text-align: left;

      padding: 0 10px 0 7px;

      color: #6b6d7e;

      &:nth-of-type(1) {
        padding-left: 16px;
      }
    }
  }

  tbody {
    td {
      height: 44px;
      vertical-align: middle;

      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;

      background-color: #f9f9fa;
      border: 1px solid #e3e5e8;
      border-width: 1px 0;

      padding: 0 10px 0 7px;

      &:nth-of-type(1) {
        padding-left: 16px;
      }
    }
  }
}
