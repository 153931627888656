@import "../../../assets/styles/common.scss";

.daypicker {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px;
  gap: 12px;
  height: 38px;
  background: $colorNeutral0;
  border: 1px solid $colorNeutral40;
  border-radius: 12px;
  transition: 200ms ease-in-out;
  cursor: pointer;
  width: 100%;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard syntax */

  &.disabled {
    border-color: $colorNeutral30;
    color: $colorNeutral50;
    background-color: $colorNeutral20;
    pointer-events: none;
  }
}

.calendar-modal__wrapper.day {
  position: absolute;
  z-index: -40;
  opacity: 0;
  visibility: hidden;
}

.calendar-modal__wrapper_active.day {
  width: 100%;
  position: absolute;
  z-index: 40;
  opacity: 1;

  .react-calendar {
    width: 100%;
    transition: opacity 0.1s linear;

    &__navigation {
      width: 100%;

      &__label {
        background: transparent;
      }
    }
    &__viewContainer {
      width: 100%;
    }
  }
}

.header-calendar__wrapper.day {
  width: 100%;
}
