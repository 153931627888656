@import "assets/styles/common.scss";

.comment {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  padding-left: 48px;

  background-color: $colorDisabled;
  border-radius: 8px;

  position: relative;

  &::before {
    content: " ";
    width: 24px;
    height: 24px;

    background: url("../../../../../../assets/icons/svg/schedule/comment_icon.svg")
      no-repeat center/contain;

    position: absolute;
    left: 16px;
    top: 16px;
  }

  p {
    color: $colorTextPrimary;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: left;

    overflow: hidden;
    text-overflow: ellipsis;
  }

  .worker {
    font-weight: 800;
  }

  .comment {
    font-weight: 500;
  }
}
