@import "assets/styles/common.scss";

.row {
  display: flex;
  align-items: center;
  gap: 24px;
}

.add-contract {
  &-form {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .buttons {
      align-self: flex-end;
    }
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    //19px padding-bottom every field
    gap: calc(24px - 19px);
    margin-bottom: -19px;
  }
}

.edit-contract {
  &-form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;

      gap: 19px;
    }
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    //19px padding-bottom every field
    gap: calc(24px - 19px);

    overflow: auto;

    &* > {
      flex-grow: 0;
      flex-shrink: 0;
    }

    padding-right: 40px;
    margin-right: -40px;

    & > .status {
      margin-bottom: 19px;
    }
  }

  &__delete {
    outline: none;
    cursor: pointer;

    border: none;
    background-color: transparent;

    display: flex;
    align-items: center;
    gap: 8px;

    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: $colorErrorNew;

    &:disabled {
      cursor: not-allowed;
      color: #999999;
    }
  }
}

.status {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;
}

.comment {
  display: flex;
  align-items: start;
  gap: 8px;

  min-height: 92px;
  height: fit-content;
  width: 100%;

  overflow: hidden;

  border-radius: 8px;

  padding: 16px 24px 16px 16px;

  h6 {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  p {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &-processed {
    border: 1px solid #6d37f6;

    color: #6d37f6;
  }

  &-done {
    background-color: #f7f7f7;

    color: black;
  }

  &-icon {
    display: grid;
    place-content: center;

    width: 20px;
    height: 20px;
  }
}
