.modalCard {
    background-color: white;
    padding: 12px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.closeButtonContainer {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    top: 6px;
    right: 6px;
}