/* Common */

label:hover {
  cursor: pointer;
}

.radioInput {
  display: none;
}

/* Unchecked */

.notChoosenGrey24 {
  display: flex;
  width: 24px;
  height: 24px;
  background-image: url("../../assets/icons/svg/radioOff.svg");
  background-repeat: no-repeat;
}

/* Checked. Change only class after "+" */

.radioInput:checked + .choosenBlack24 {
  display: flex;
  width: 24px;
  height: 24px;
  background-image: url("../../assets/icons/svg/radioOn.svg");
  background-repeat: no-repeat;
}

/* Disabled. Change only class after "+" */

.radioInput:disabled + .choosenBlack24 {
  display: flex;
  width: 24px;
  height: 24px;
  background-image: url("../../assets/icons/svg/radioDisabled.svg");
  background-repeat: no-repeat;
  cursor: default;
}

/* Cheched Disabled. Change only class after "+" */

.radioInput:checked:disabled + .choosenBlack24 {
  display: flex;
  width: 24px;
  height: 24px;
  background-image: url("../../assets/icons/svg/radioCheckedDisabled.svg");
  background-repeat: no-repeat;
  cursor: default;
}
