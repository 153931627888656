@import "../../assets/styles/common.scss";

.popupModal__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  position: absolute;
  z-index: 300;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: $colorNeutral0;

  .ant-radio-wrapper {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }

  span.title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $colorSecondary;
    margin-bottom: 4px;
  }

  /* Shadow_modal_1 */
  box-shadow:
    0 2px 8px rgba(0, 0, 0, 0.03),
    0 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  .popupModal__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 20px 8px;
    gap: 16px;

    .popupModal__title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-grow: 1;
      .title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: $colorNeutral90;
      }

      .subtitle {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: $colorNeutral50;
      }
    }
    .popupModal__exitButton {
      border: none;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 4px;
      gap: 8px;
      background: $colorNeutral10;
      border-radius: 8px;
      transition: 200ms ease-in-out;
      &:hover {
        background: $colorNeutral20;
      }
      &:active {
        background: $colorNeutral30;
      }
    }
  }
  .popupModal__main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 16px;
    width: 100%;
    // p {
    //   font-family: 'Inter';
    //   font-style: normal;
    //   font-weight: 500;
    //   font-size: 14px;
    //   line-height: 22px;
    //   color: $colorNeutral50;
    //   margin-bottom: 4px;
    // }

    .popupModal__element {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 4px;

      .label {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: $colorNeutral50;
      }
    }
  }
  .popupModal__footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 16px;
    width: 100%;
  }
}

.popupModal__overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(44, 44, 44, 0.4);
  z-index: 299;
  cursor: pointer;
}
